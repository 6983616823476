import {
  Checkbox,
  FormControl,
  Grid,
  Switch, Typography,
} from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextInputWithSideLabel, {
  BaseButton,
  InputLabel,
  InputText,
} from '../common/Form';
import Divider from '@mui/material/Divider';
import { useContext, useState } from 'react';
import { CalculatorContext } from '../../context/calculator/CalculatorProvider';
import { formatNumberWithComma, numberWithCommas, updateState } from './utils';
import { BlueGradientText } from '../common/PublicTypography';

const Root = styled('div')`
`;

const BoxRoot = styled(Box)`
  ${props => props.theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(2.5)};
  }
  font-size: 18px;
`;

const BoxInner = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2.5)};
`;

const BlueBox = styled(BoxRoot)`
  background-color: rgba(42, 131, 226, 0.2);
`

export const TextSizeXXL = styled(BlueGradientText)`
  font-size: 66px;
  line-height: 1;
  word-break: break-word;
  &.long-txt {
    font-size: 56px;
  }
  &.super-long-txt {
    font-size: 46px;
  }  
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 90px;
    &.long-txt, &.super-long-txt {
      font-size: 76px;
    }
  }
`

export const TextSizeXL = styled(TextSizeXXL)`
  font-size: 22px;
  line-height: 1.4;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: inherit;
  }
`
const TextRegular = styled(Typography)`
  font-size: 18px;
`;

const ButtonGroupItem = ({ children, defaultValue, selected, onSelect, className }) => (
  <BaseButton
    className={className}
    onClick={() => onSelect(defaultValue)}
    variant={selected === defaultValue ? 'contained-x' : 'outlined-x'}
    fullWidth>{children}</BaseButton>
);

const StyledButtonGroupItem = styled(ButtonGroupItem)`
  padding: 3.5px 0;
  line-height: 43px;
  box-sizing: border-box;
`;

ButtonGroupItem.propTypes = {
  defaultValue: PropTypes.number,
  selected: PropTypes.number,
  onSelect: PropTypes.func,
  className: PropTypes.string,
};

const AdvancedControlBox = styled(Box)`
  padding: 20px;
  background-color: #F7F7F7;
  border-radius: 6px;
`;

const InputRootGrey = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: #F7F7F7;
  border-radius: 6px;
  display: flex;
  flex: 1;
`;

const LabelRootGrey = styled(InputRootGrey)`
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-family: ProximaNovaSemiBold;
  ${props => props.theme.breakpoints.down('md')} {
    line-height: 52px;
  }
`

const HorizontalDivider = styled(Divider)`
  position: relative;
  ${props => props.theme.breakpoints.down('md')} {
    margin: 20px 0;
  }
`;

const ToggleBtn = styled('span')`
  text-decoration-line: underline;
  cursor: pointer;
  font-family: ProximaNovaSemiBold;
  display: inline-block;
`;

const InputTextWhite = styled(InputText)`
  background-color: white;
`;

const LabelInputRow = styled(InputLabel)`
  margin: 0;
`;

const PublicCalculator = ({
  formatCurrency,
}) => {
  const { calc: {
    dailyPageviews, lostRevenue, imageToVideoRatioPercentage, matchPercentage, fillRatePercentage,
    autoCpm, isCtp, isProgrammatic, includeHeaderbidding, potentialVideoMatches,
  }, dispatch } =  useContext(CalculatorContext);
  const update = updateState(dispatch);
  const [showAdvancedControl, setShowAdvancedControl] = useState(false);
  const lostRevenueText = numberWithCommas(formatCurrency(lostRevenue * 30));

  const classTextDecrease = (length) => {
    if (length >= 10) {
      return 'super-long-txt';
    }

    if (length >= 7) {
      return 'long-txt'
    }

    return ''
  }
  return (<React.Fragment>
    <Root sx={{ mb: 4 }}>
      <BoxRoot>
        <BoxInner sx={{ pb: 0 }}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} sm={6}>
              <TextInputWithSideLabel
                type={'text'}
                value={dailyPageviews}
                onChange={(val) => update('dailyPageviews', formatNumberWithComma(val))}
                label={'Daily page views'}
                sideLabel={'Views'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>% of pages missing video</InputLabel>
                <InputRootGrey>
                  <Grid container spacing={2}>
                    <Grid item xs={4}><StyledButtonGroupItem onSelect={update('imageToVideoRatioPercentage')}
                                                             selected={imageToVideoRatioPercentage}
                                                             defaultValue={80}>80%</StyledButtonGroupItem></Grid>
                    <Grid item xs={4}><StyledButtonGroupItem onSelect={update('imageToVideoRatioPercentage')}
                                                             selected={imageToVideoRatioPercentage}
                                                             defaultValue={60}>60%</StyledButtonGroupItem></Grid>
                    <Grid item xs={4}><StyledButtonGroupItem onSelect={update('imageToVideoRatioPercentage')}
                                                             selected={imageToVideoRatioPercentage}
                                                             defaultValue={40}>40%</StyledButtonGroupItem></Grid>
                  </Grid>
                </InputRootGrey>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <AdvancedControlBox>
                <ToggleBtn onClick={() => setShowAdvancedControl(!showAdvancedControl)}>{ showAdvancedControl ? 'Hide'   : 'Show' } advanced control</ToggleBtn>
                <Box sx={{ display: showAdvancedControl ? 'block' : 'none', marginTop: 2 }}>
                  <span>Refine your calculations</span>
                  <Grid container alignItems={'center'} sx={{ paddingTop: 1 }}>
                    <Grid item sx={{ paddingRight: 1 }}>
                      <InputTextWhite type={'number'} onChange={({ target: { value }}) => update('fillRatePercentage', value)} value={fillRatePercentage} style={{ backgroundColor: 'white', width: '70px', textAlign: 'center' }}/>
                    </Grid>
                    <Grid item sx={{ paddingRight: 2 }}>
                      <LabelInputRow>% Fill</LabelInputRow>
                    </Grid>
                    <Grid item sx={{ paddingRight: 2 }}>
                      <Checkbox id={'headerbidding'} checked={includeHeaderbidding} onClick={() => update('includeHeaderbidding', !includeHeaderbidding)}/>
                      <LabelInputRow htmlFor={'headerbidding'}>Header-bidding</LabelInputRow>
                    </Grid>
                    <Grid item>
                      <Checkbox id={'directsales'} checked={!isProgrammatic} onClick={() => update('isProgrammatic', !isProgrammatic)}/>
                      <LabelInputRow htmlFor={'directsales'}>Direct sales</LabelInputRow>
                    </Grid>
                  </Grid>
                </Box>
              </AdvancedControlBox>
            </Grid>
          </Grid>
        </BoxInner>
      </BoxRoot>
      <HorizontalDivider orientation={'horizontal'}/>
      <BoxRoot>
        <BoxInner sx={{ pt: 1, pb: 1 }}>
          <Grid container spacing={2.5} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{ height: '100%' }}>
                <InputLabel>We would estimate...</InputLabel>
                { !isNaN(potentialVideoMatches) && <LabelRootGrey>{numberWithCommas(Math.floor(potentialVideoMatches))} Extra Daily Plays</LabelRootGrey>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{ height: '100%' }}>
                <InputLabel>Current average CPM</InputLabel>
                 <InputRootGrey>
                  <Grid container spacing={2}>
                    <Grid item xs={4}><StyledButtonGroupItem onSelect={update('autoCpm')}
                                                             selected={autoCpm}
                                                             defaultValue={5}>$5</StyledButtonGroupItem></Grid>
                    <Grid item xs={4}><StyledButtonGroupItem onSelect={update('autoCpm')}
                                                             selected={autoCpm}
                                                             defaultValue={7}>$7</StyledButtonGroupItem></Grid>
                    <Grid item xs={4}><StyledButtonGroupItem onSelect={update('autoCpm')}
                                                             selected={autoCpm}
                                                             defaultValue={10}>$10</StyledButtonGroupItem></Grid>
                  </Grid>
                </InputRootGrey>
              </FormControl>
            </Grid>
          </Grid>
        </BoxInner>
      </BoxRoot>
      <BlueBox>
        <BoxInner>
          <Grid container>
            <Grid id={'revenueBox'} item xs={12} sx={theme => ({
              backgroundColor: 'white', pt: 5, pb: 4,
              borderBottom: '1.5px solid rgba(42, 131, 226, 0.2)',
              borderRadius: '9px 9px 0 0',
              [theme.breakpoints.down('md')]: {
              },
              [theme.breakpoints.up('md')]: {
                borderBottomWidth: '3px',
              }
            })} alignItems={'center'}>
              <TextSizeXXL className={classTextDecrease(String(lostRevenueText).length)} textAlign={'center'} variant={'body1'}>{lostRevenueText}</TextSizeXXL>
              <TextSizeXL textAlign={'center'} variant={'body1'}>Extra Revenue Per Month</TextSizeXL>
            </Grid>
            <Grid item xs={12} sm={6} sx={theme => ({
              backgroundColor: 'white',
              borderBottom: '1.5px solid rgba(42, 131, 226, 0.2)',
              borderRadius: '0 0 0 0',
              pt: 2, pb: 2,
              [theme.breakpoints.up('md')]: {
                pt: 3, pb: 2,
                borderRight: '1.5px solid rgba(42, 131, 226, 0.2)',
                borderBottom: 0,
                borderRadius: '0 0 0 9px',
              }
            })
            }>
              <TextSizeXL textAlign={'center'}>{matchPercentage}%<br />Video match rate</TextSizeXL>
              <TextRegular textAlign={'center'}>Automatically matched</TextRegular>
            </Grid>
            <Grid item xs={12} sm={6} sx={theme => ({
              backgroundColor: 'white',
              borderRadius: '0 0 9px 9px',
              pt: 2, pb: 2,
              [theme.breakpoints.up('md')]: {
                pt: 3, pb: 2,
                borderLeft: '1.5px solid rgba(42, 131, 226, 0.2)',
                borderTop: 0,
                borderRadius: '0 0 9px 0',
              }
            })}>
              <TextSizeXL textAlign={'center'} variant={'body1'}>
                {potentialVideoMatches/1000}% <br/> CPM Uplift
              </TextSizeXL>
              <TextRegular textAlign={'center'} variant={'body1'}>With more relevant video</TextRegular>
            </Grid>
          </Grid>
          <Grid container justifyContent={'center'} alignItems={'center'} sx={{ p: 3 }} spacing={1}>
            <Grid item>
              <TextRegular>Autoplay</TextRegular>
            </Grid>
            <Grid item>
              <Switch
                checked={isCtp}
                onChange={() => update('isCtp', !isCtp)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item>
              <TextRegular>Click to play</TextRegular>
            </Grid>
          </Grid>
        </BoxInner>
      </BlueBox>
    </Root>
  </React.Fragment>);
};

PublicCalculator.propTypes = {
  formatCurrency: PropTypes.func,
  selectedPublisher: PropTypes.string,
  publishers: PropTypes.array,
  setPublisher: PropTypes.func
};

export default PublicCalculator;
