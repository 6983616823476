
export type CalculatorState = {
  dailyPageviews: string
  lostRevenue: number
  imageToVideoRatioPercentage: number
  matchPercentage: number
  fillRatePercentage: number
  d2p: number
  d2e: number
  ctpCpm: number
  autoCpm: number
  upliftProg: number
  upliftDirect: number
  currency: string
  isCtp: boolean
  isProgrammatic: boolean
  includeHeaderbidding: boolean
  potentialVideoMatches: number
  adReqs: number
  upliftedCpm: number
}
export const initialState = {
  dailyPageviews: '1,000,000',
  lostRevenue: 0,
  imageToVideoRatioPercentage: 80,
  matchPercentage: 50,
  fillRatePercentage: 70,
  d2p: 0.83,
  d2e: 0.94,
  ctpCpm: 4,
  autoCpm: 5,
  upliftProg: 2,
  upliftDirect: 0.9,
  currency: 'dollar',
  isCtp: false,
  isProgrammatic: true,
  includeHeaderbidding: false,
  potentialVideoMatches: 0,
  adReqs: 0,
  upliftedCpm: 0
}

export const initializer = (initialValue = initialState) => {
  const local = window.localStorage.getItem("localCalculator");
  if (local) {
    try {
      return JSON.parse(local)
    } catch (ex) {
      console.error(ex);
      return initialValue;
    }
  }
  return initialValue
}


type AnyAction = {
  type: string,
  payload: any
}

export const calculatorReducer = (state: CalculatorState, action: AnyAction): CalculatorState => {
  switch (action.type) {
    case 'UPDATE': {
      const [key, value] = action.payload;
      return {
        ...state,
        [key]: value
      }
    }
    default:
      return state;
  }
};