import { ReactComponent as GreenDotIcon } from '../../icons/green-dot.svg';
import { ReactComponent as RedDotIcon } from '../../icons/red-dot.svg';
import { ReactComponent as KeyIcon } from '../../icons/key.svg';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { BoldTypographyLarge } from './TextLabel';

const KeyWordMatch = styled('div')`
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }
`;

const MatchPercentileRoot = styled('span')`
  padding-left: 6px;
`;

const TextNoWrap = styled(BoldTypographyLarge)`
  white-space: nowrap;
`;

const editorial_delta = data => {
  //@TODO: replace this hacky hack to add something to the UI to show video match uplift...
  if (data.editorial_delta !== null) {
    if (data.editorial_delta !== 0) {
      let sign = '';
      if (data.editorial_delta > 0) {
        //need to add + if positive delta...
        sign = '+';
      }
      return ` (${sign}${data.editorial_delta}%)`;
    }
    return ` (+0%)`;
  }
};

export const MatchType = ({ data }) => {
  if (/Databricks/.test(data.match_type)) {
    return (
      <TextNoWrap title={'Match score'}>
        {data.match_is_good && (
          <>
            <GreenDotIcon />{' '}
            <MatchPercentileRoot>
              {data.score}%{editorial_delta(data)}
            </MatchPercentileRoot>
          </>
        )}
        {!data.match_is_good && (
          <>
            <RedDotIcon />{' '}
            <MatchPercentileRoot>
              {data.score}%{editorial_delta(data)}
            </MatchPercentileRoot>
          </>
        )}
      </TextNoWrap>
    );
  }

  if (/keyword/i.test(data.match_type)) {
    return (
      <KeyWordMatch>
        <KeyIcon title="keyword match" />
      </KeyWordMatch>
    );
  }
  return null;
};
