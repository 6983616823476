import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const CarouselRoot = styled(Box)`
  background-color: white;
  position: relative;
  align-items: center;
  ${props => props.theme.breakpoints.up('md')} {
    padding-top: 28px;
  }
`;

const CarouselWrapper = styled(Box)`
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 36))
    }
  }
  background: white;
  height: 55px;
  display: flex;
  align-items: center;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${props => props.theme.breakpoints.up('md')} {
    max-width: 1200px;
    height: 100px;
  }

  &::before,
  &::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100px;
    position: absolute;
    width: 80px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
`;

const CarouselTrack = styled(Box)`
  animation: scroll 180s linear infinite;
  display: flex;
  width: calc(250px * (36*2));
`;

const CarouselItem = styled(Box)`
  height: 55px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.breakpoints.up('md')} {
    width: 250px;
    height: 75px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Title = styled(Typography)`
  font-size: 13px;
  color: #0B2954;
  text-align: center;
  font-weight: bold;
  line-height: 2rem;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 22px;
  }
`;

const BrandedCarousel = () => {
  const brands = [15, 16, 17, 18, 24, 30, 7, 21, 13, 14, 27, 4, 2, 5, 6, 11, 33, 10, 9, 8, 12, 23, 28, 35, 34, 31, 29, 26, 25, 22, 20, 19, 3];
  return <CarouselRoot>
    <Title>Optimised Using the World’s Largest Editorial Video Collection</Title>
    <CarouselWrapper>
      <CarouselTrack>
        {brands.map((x) => (<CarouselItem key={`r1${x}`}>
          <img src={`/brands/Frame%20${x}.png`} alt=""/>
        </CarouselItem>))}
        {brands.map((x) => (<CarouselItem key={`r2${x}`}>
          <img src={`/brands/Frame%20${x}.png`} alt=""/>
        </CarouselItem>))}
      </CarouselTrack>
    </CarouselWrapper>
  </CarouselRoot>;
};

export default BrandedCarousel;