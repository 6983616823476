import {
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  InputLabel, OutlinedInput,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';

import { Countries } from '../../constant/countries';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

const CloseBtnRoot = styled('div')`
  position: absolute;
  right: 10px;
  top: 10px
`;

const Root = styled('div')`
  padding: 20px 40px 0; 
  position: relative;
`;

const TitleDialog = styled(DialogTitle)`
  position: relative;
  padding-top: 0;
  &:after {
    content: ' ';
    background-color: #ccc;
    width: calc(100% + 80px);
    height: 1px;
    position: absolute;
    left: -40px;
    bottom: 10px;
  }
`;

const Title = styled('div')`
  text-transform: capitalize;
  font-weight: bold;
  margin: 24px 0 0px 0;
`;

const promotedContentKeys = [
  'enabled',
  'videoId',
  'pathInclude',
  'pathExclude',
  'totalImpressionTarget',
  'geoCountryCode',
  'geoCountryCodeExclusion',
  'startTimestamp',
  'endTimestamp',
  'lastEditedTimestamp',
  'priority',
  'dailyImpressionTarget',
  'orgSlug',
  'pk',
  'sk',
];

type PromotedContent = {
  enabled: boolean;
  videoId: string;
  pathInclude: string[];
  pathExclude: string[];
  totalImpressionTarget: number;
  geoCountryCode: string[];
  geoCountryCodeExclusion: string[];
  startTimestamp: number;
  endTimestamp: number;
  lastEditedTimestamp?: number;
  priority?: number;
  dailyImpressionTarget: number;
  orgSlug: string;
  pk: string;
  sk: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      maxWidth: 250,
    },
  },
};

function getStyles(name: string, codes: string[], theme: Theme) {
  return {
    fontWeight:
      codes.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : 'bold',
  };
}

const stateChange = (key, setValue, state: PromotedContent) => ({ target: { type, value } }) => {
  setValue({
    ...state,
    [key]: /datetime-local|number/.test(type) && value ? Number(value) : value
  });
};

const removeNonDtoProps = (data): PromotedContent => Object.keys(data)
  .filter(key => promotedContentKeys.includes(key)).reduce((obj, key) => {
    obj[key] = data[key];
    return obj;
  }, {} as PromotedContent);

export const EditBrandedContentDialog = ({
  open,
  data,
  onSave,
  handleClose,
}: { onSave: (x: PromotedContent) => void, open: boolean, handleClose: () => void, data: PromotedContent }) => {
  const theme = useTheme();
  const [updatedData, setUpdatedData] = useState<PromotedContent>(removeNonDtoProps(data));
  const [valid, setValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [vIdHasBeenTouch, setVIdHasBeenTouch] = useState(false);

  useEffect(() => {
    const invalidFields: (string[] | false)[] = Object.entries(updatedData).map<string[] | false>(([key, value]) => {
      const errorWithKey = (k => err => [k, err])(key);
      switch (key) {
        case 'videoId': {
          return value ? false : errorWithKey('Video ID can not be empty');
        }
        case 'startTimestamp': {
          return value > 0 && value > updatedData.endTimestamp ? errorWithKey('Start time can' +
            ' not be bigger than end timestamp') : false;
        }
        case 'endTimestamp': {
          return value > 0 && value < updatedData.startTimestamp ? errorWithKey('Ending time can not be' +
            ' smaller than end timestamp') : false;
        }
        case 'dailyImpressionTarget': {
          return value > 0 && value > updatedData.totalImpressionTarget ? errorWithKey('Daily' +
            ' target can not be bigger than total target') : false;
        }
        case 'totalImpressionTarget': {
          return value > 0 && value < updatedData.dailyImpressionTarget ? errorWithKey('Total' +
            ' target can not be smaller than daily target') : false;
        }
        default:
          return false;
      }
    }).filter(x => x);

    setValid(invalidFields.length === 0);
    setErrors(Object.fromEntries(invalidFields as string[][]));
  }, [updatedData]);

  useEffect(() => {
    const { totalImpressionTarget, startTimestamp, endTimestamp } = updatedData
    if (
      totalImpressionTarget > 0 &&
      startTimestamp > 0 && endTimestamp > 0
    ) {
      const diff = Math.ceil((endTimestamp - startTimestamp) / 86400);

      setUpdatedData({
        ...updatedData,
        dailyImpressionTarget: Math.ceil(totalImpressionTarget / diff),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData.totalImpressionTarget, updatedData.startTimestamp, updatedData.endTimestamp]);

  return (<Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <Root>
        <CloseBtnRoot>
          <IconButton onClick={handleClose}>
            <CloseIcon/>
          </IconButton>
        </CloseBtnRoot>
        <TitleDialog><Title>Promoted Content</Title></TitleDialog>
        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: 0 }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField id="videoId" label="Video ID" variant="outlined"
                           type={'text'}
                           onChange={stateChange('videoId', setUpdatedData, updatedData)}
                           value={updatedData.videoId}
                           error={vIdHasBeenTouch && Boolean(errors.videoId)}
                           helperText={vIdHasBeenTouch && errors.videoId}
                           onKeyPress={() => !vIdHasBeenTouch && setVIdHasBeenTouch(true)}
                           disabled={Boolean(updatedData.sk)}/>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField id="priority" type="number" label="Priority" variant="outlined"
                           value={updatedData.priority}
                           onChange={stateChange('priority', setUpdatedData, updatedData)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="startTimestamp"
                  label="Start"
                  type="datetime-local"
                  error={Boolean(errors.startTimestamp)}
                  helperText={errors.startTimestamp}
                  defaultValue={new Date(updatedData.startTimestamp * 1000).toISOString().replace(/:[\d.Z]+$/, '')}
                  onChange={({ target: { value } }) => setUpdatedData({
                    ...updatedData,
                    'startTimestamp': value ? Math.floor(new Date(value).getTime() / 1000) : 0
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="endTimestamp"
                  label="End"
                  type="datetime-local"
                  error={Boolean(errors.endTimestamp)}
                  helperText={errors.endTimestamp}
                  defaultValue={new Date(updatedData.endTimestamp * 1000).toISOString().replace(/:[\d.Z]+$/, '')}
                  onChange={({ target: { value } }) => {
                    setUpdatedData({
                      ...updatedData,
                      'endTimestamp': value ? Math.floor(new Date(value).getTime() / 1000) : 0
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>

             <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField type="number" id="totalTarget" label="Total impression target"
                           variant="outlined"
                           onChange={stateChange('totalImpressionTarget', setUpdatedData, updatedData)}
                           error={Boolean(errors.totalImpressionTarget)}
                           helperText={errors.totalImpressionTarget}
                           value={updatedData.totalImpressionTarget}/>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField type="number" id="dailyTarget" label="Daily impression target"
                           variant="outlined"
                           onChange={stateChange('dailyImpressionTarget', setUpdatedData, updatedData)}
                           error={Boolean(errors.dailyImpressionTarget)}
                           helperText={errors.dailyImpressionTarget}
                           value={updatedData.dailyImpressionTarget}/>
              </FormControl>
            </Grid>

            {['geoCountryCode', 'geoCountryCodeExclusion'].map(prop => (<Grid key={prop} item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="multiple-country-inclusion">Country { prop === 'geoCountryCode' ? 'inclusion' : 'exclusion'}</InputLabel>
                <Select
                  labelId="multiple-country-inclusion"
                  id="multiple-country-inclusion-selection"
                  multiple
                  value={updatedData[prop]}
                  onChange={(e: SelectChangeEvent<string[]>) => {
                    const value = e.target.value;
                    if (value[value.length - 1] === 'all') {
                      setUpdatedData({
                        ...updatedData,
                        [prop]: updatedData[prop].length === Countries.length ? [] : Countries.map(({ alpha2 }) => alpha2)
                      });
                      return;
                    }

                    setUpdatedData({
                      ...updatedData,
                      [prop]: value as string[]
                    });
                  }}
                  input={<OutlinedInput label="Name"/>}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                  >
                    <Checkbox checked={Countries.length > 0 && updatedData[prop].length === Countries.length} style={{ padding: 0 }}/>
                    Select all countries
                  </MenuItem>
                  {Countries.map(({ alpha2, name }) => (
                    <MenuItem
                      key={alpha2}
                      value={alpha2}
                      style={getStyles(alpha2, updatedData[prop], theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>))}

            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox onChange={({ target: { checked } }) => setUpdatedData({
                  ...updatedData,
                  enabled: checked
                })}
                                   checked={updatedData.enabled}/>}
                label="Enable"/>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={'flex-end'}>
                <Button color="primary" variant="contained" disabled={!valid}
                        onClick={() => onSave(updatedData)}>Save</Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Root>
    </Dialog>
  );
};