import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';

const fetchSmartLibrarySuccess = data => ({
  type: 'FETCH_ITEMS_SUCCESS',
  payload: data
});

const updateBusyIndicator = val => ({
  type: 'KEYWORD_REQUEST_ON_PROGRESS',
  payload: val
});

export const apiSmartLibraryProvider = ({
  publisher,
  ...params
}) =>
  async dispatch => {
    try {
      dispatch(updateBusyIndicator(true));
      let { data } = await axios.get(`${getApiBaseUrl()}/${publisher}/smart-library`, {
        params,
      });
      dispatch(fetchSmartLibrarySuccess(data));
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      updateBusyIndicator(false);
      console.error(e);
    }
  };

export interface EngageState {
  items: Record<string, any> | null;
  paginationPerPage: number;
  paginationPage: number;
  filterDateRange: string;
  filterArticleType: string;
  filterHasRanked: boolean;
  busy: boolean;
  pagination: {
    currentPage: number,
    itemCount: number,
    itemsPerPage: number
    totalItems: number
    totalPages: number
  };
}

const initialState: EngageState = {
  items: null,
  paginationPerPage: 20,
  paginationPage: 0,
  filterDateRange: '1day',
  filterArticleType: 'image-only',
  filterHasRanked: true,
  busy: false,
  pagination: {
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 50,
    totalItems: 0,
    totalPages: 0,
  }
};

const smartLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PAGINATION': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        }
      };
    }
    case 'FETCH_ITEMS_SUCCESS':
      let { items, meta: pagination } = action.payload;
      return {
        ...state,
        items: items.filter(Boolean),
        pagination,
      };
    case 'SET_FILTER_ARTICLES':
      return {
        ...state,
        filterDateRange: action.payload.filterDateRange,
        filterHasRanked: action.payload.filterHasRanked,
        filterArticleType: action.payload.filterArticleType,
        pagination: {
          ...state.pagination,
          currentPage: 1,
        }
      }
    case 'KEYWORD_REQUEST_ON_PROGRESS': {
      return {
         ...state,
        busy: action.payload
      }
    }
    default:
      return state;
  }
};

export default smartLibraryReducer;
