import playButton from '../../icons/play.png';
import * as React from 'react';
import { styled } from '@mui/material/styles';

const Root = styled('div')`
  position: relative;
  > video {
    position: absolute;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  > div {
    transition: opacity 0.2s;
  }
  
  &.preview-mode {
    > video {
      opacity: 1;
    }
    > div {
      opacity: 0;
    }
  }
`

const CountVideo = styled('div')`
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #FF512D;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: 'ProximaNovaBold';
`

export const VideoThumbnail = ({ imageUrl, videoCount, width, preview, previewVideoUrl }) => (<Root sx={{ width }} className={preview ? 'container preview-mode' : 'container'}>
  { previewVideoUrl && <video width={width} height={width * 0.63} controls={false} loop muted autoPlay>
    <source src={previewVideoUrl} type="video/mp4" />
  </video> }
  <div
    style={{
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: width * 0.63,
      borderRadius: '5%',
    }}
  />
  { videoCount && videoCount > 1 ? <CountVideo>{videoCount}</CountVideo> : ''}
  <div className="play-icon">
    <img width={width} src={playButton} alt="play button"/>
  </div>
</Root>);


VideoThumbnail.defaultProps = {
  width: 90,
  preview: false,
  previewVideoUrl: null
}