import { GlobalStyles } from '@mui/material';
import Helmet from 'react-helmet';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import PublicLayout from './PublicLayout';
import ContactForm from '../contact/ContactForm';

const ContactUs = styled(ContactForm)`
  margin-bottom: 75px;
`

function Contact({ className }) {
  return (
    <PublicLayout className={className}>
      <GlobalStyles styles={{
        body: {
          backgroundColor: '#273A81'
        }
      }} />
      <Helmet title="Pixels.Ai - Contact us">
        <meta name="description" content="Unlock the full potential of your video business with our expert guidance. Contact us today and let our team of professionals help you maximize your video revenue, optimize your content strategy, and grow your audience. Get the support you need to take your video business to the next level - contact us now!"/>
      </Helmet>
      <ContactUs />
    </PublicLayout>
  );
}

Contact.propTypes = {
  className: PropTypes.string
}

export default styled(Contact)`
  header {
    background-color: #0B2954;
  }
`;
