import * as React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { GlobalStyles, Grid } from '@mui/material';
import Box from '@mui/material/Box';

import PublicLayout from './PublicLayout';
import Calculator from '../Calculator';
import { BlueGradientText, Headline, Paragraph } from '../common/PublicTypography';
import ContactForm from '../contact/ContactForm';
import { useContext } from 'react';
import { CalculatorContext } from '../../context/calculator/CalculatorProvider';
import {
  formatCurrency,
  getCurrencyRate,
  getCurrencySymbol,
  numberWithCommas
} from '../calculator/utils';

const ContentRoot = styled(Box)`
  border-radius: 9px;
  background-color: #fff;
  max-width: 840px;
  color: #2E434E;
  margin-top: 38px;
`;

const ContactFormCalculator = styled(ContactForm)`
  margin-bottom: 75px;
`

const StickyRevenue = styled(Box)`
  background: #fff;
  padding: 0 20px;
  position: fixed;
  bottom: 12px;
  left: 12px;
  z-index: 100;
  width: calc(100vw - 24px);
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(100%);
  transition: all .3s ease-out;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
  
  .revenue-total-visible & {
    opacity: 1;
    transform: translateY(0);
  }
`;

const RevenueAmount = styled(BlueGradientText)`
  font-size: 40px;
  margin-right: 9px;
`;

const RevenueText = styled(BlueGradientText)`
  font-size: 16px;
  line-height: 1.2;
`

const NoWrap = styled('span')`
  white-space: nowrap;
`

function RevenueLossCalculator({ className }) {
  const { calc } = useContext(CalculatorContext);

  return (
    <PublicLayout className={className}>
      <GlobalStyles styles={{
        body: {
          backgroundColor: '#273A81',
          backgroundImage: 'url(/vector-bg/vector-bg-calculator.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center 500px',
          backgroundSize: '120%',
        }
      }}/>
      <Helmet title="Pixels.Ai - Revenue Loss Calculator">
        <meta name="description" content="Want to know how much revenue you could generate with Pixel Ai's video  solutions? Try our revenue calculator and get a personalized estimate based on your video content and audience metrics. Discover the potential of your video business and start optimizing your revenue streams with Pixel Ai today."/>
        <script defer src={'/observeBox.js'}></script>
      </Helmet>

      <Grid container>
        <Grid item xs={12} justifyContent={'center'} textAlign={'center'}
              sx={{ paddingTop: 9.375 }}>
          <Headline variant={'h1'}>
            What do missing videos cost your business?
          </Headline>
          <Paragraph variant={'body1'}>Pixels Ai uses the article top slot to create better user engagement and higher CPMs</Paragraph>
        </Grid>
      </Grid>
      <ContentRoot>
        <Calculator selectedPublisher={null} publishers={null} setPublisher={null} />
      </ContentRoot>
      <ContactFormCalculator />
      <StickyRevenue><RevenueAmount>{numberWithCommas(formatCurrency(calc.lostRevenue * 30, getCurrencySymbol(calc.currency), getCurrencyRate(calc)))}</RevenueAmount> <RevenueText><NoWrap>Extra Revenue</NoWrap> <NoWrap>Per Month</NoWrap></RevenueText></StickyRevenue>
    </PublicLayout>
  );
}

RevenueLossCalculator.propTypes = {
  className: PropTypes.string
};

export default styled(RevenueLossCalculator)`
  header {
    background-color: #0B2954;
  }

  
`;
