import { styled } from '@mui/material/styles';
import _ListItemText from '@mui/material/ListItemText';
import { NavLink } from 'react-router-dom';

export const BoldTypography = styled('span')`
  font-family: ProximaNovaBold; 
  font-size: 12px;
`;

export const BoldTypographyMedium = styled(BoldTypography)`
  font-size: 14px;
`

export const BoldTypographyLarge = styled(BoldTypography)`
  font-size: 16px;
`

export const DrawerListItemText = styled(_ListItemText)`
  font-family: 'ProximaNovaSemiBold';
`
export const SidebarLink = styled(NavLink)`
  text-decoration: none;
  color: rgb(72, 162, 250); 
  padding-top: 6px;
  padding-bottom: 6px;
  display: block;
  &.active {
    color: white;
    text-decoration: none;
    
    svg {
      fill: white;
    }
  }
  &.inactive {
    text-decoration: none;
  }
  svg {
    fill: rgb(72, 162, 250); 
  }
`