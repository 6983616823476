import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiSmartLibraryProvider } from '../store/smartLibrarySlice';
import PageToolbar from './PageToolbar';
import TableHead from '@mui/material/TableHead';
import { TableCellHead, TableRow } from './common/Table';
import TableBody from '@mui/material/TableBody';
import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
  TypographyProps
} from '@mui/material';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import { VideoImageSwapDialog } from './modal/VideoImageSwapDialog';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import PaginationTable from './Pagination';
import { Rows } from './SmartLibraryRow';

export const TableRowPrimary = styled(TableRow)`
  background-color: #fff;
`;

export const VideoRowRoot = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Capitalize = styled(Typography)`
 text-decoration: none;
 text-transform: capitalize;
 font-family: 'ProximaNovaBold';
 font-size: 16px;
 position: relative;
`;

const Root = styled('div')`
  position: relative;
  .linear-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  .linear-top {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
`;

type MyT = React.ComponentType<TypographyProps<'span', { component: 'span' }>>;

export const GreyTypography: MyT = styled(Typography)`
  color: #78909C;
`;

export const Overlay = styled('div')`
  background-color: #fff;
  opacity: 0.4;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export default function SmartLibrary({
  publishers,
  setPublisher,
  selectedPublisher
}) {
  const dispatch = useDispatch();
  const [loadingId, setLoadingId] = React.useState(null);
  const [articleVideoPreview, setArticleVideoPreview] = React.useState<any>(false);
  const [includeExternal, setIncludeExternal] = React.useState<boolean>(false);
  const [filterByDate, setDateRange] = React.useState<string>('1day');
  const [videoStatus, setVideoStatus] = React.useState<string>('all');
  const [searchType, setSearchType] = React.useState<any>([]);
  const [previewIndex, setPreviewIndex] = React.useState<any>(null);
  const items = useSelector(
    (state: any) => state.smartLibrary.items
  );

  const keywordsFilterType = useSelector(
    (state: any) => state.smartLibrary.filterDateRange
  );

  const filterHasRanked = useSelector(
    (state: any) => state.smartLibrary.filterHasRanked
  );

  const filterArticleType = useSelector(
    (state: any) => state.smartLibrary.filterArticleType
  );

  const pagination = useSelector(
    (state: any) => state.smartLibrary.pagination
  );

  const busy = useSelector(
    (state: any) => state.smartLibrary.busy
  );

  const updateList = () => {
    dispatch<any>(apiSmartLibraryProvider({
      publisher: selectedPublisher,
      limit: pagination.itemsPerPage,
      page: pagination.currentPage,
      viewType: 'published',
      orderBy: 'keyword',
      orderDirection: 'desc',
      filterByDate,
      searchType: searchType[0],
      search: searchType[1],
      videoStatus,
      includeExternal,
    }));
  };

  const createPost = (articleId, videoId) => {
    setLoadingId(articleId);
    axios
      .post(
        `${getApiBaseUrl()}/${selectedPublisher}/article-match/${articleId}/send/?video_id=${videoId}`,
        {},
      )
      .then((response) => {
        if (response.status === 201) {
          dispatch({ type: 'MATCH_UPDATE', payload: articleId });
          handleClose();
          updateList();
          setLoadingId(null);
        }
      });
  };


  const removeVideo = (articleId, videoId) => {
    axios
      .delete(
        `${getApiBaseUrl()}/${selectedPublisher}/article-match/${articleId}?video_id=${videoId}`,
      ).then(() => {
      handleClose();
      updateList();
    });
  };

  const handleClose = () => {
    setArticleVideoPreview(null);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(updateList, [
    selectedPublisher,
    pagination.itemsPerPage,
    pagination.currentPage,
    keywordsFilterType,
    filterArticleType,
    filterHasRanked,
    searchType,
    videoStatus,
    filterByDate,
    includeExternal
  ]);

  React.useEffect(() => {
    if (items) {
      setPreviewIndex(items.findIndex((item) => item.has_video))
    }
  }, [items])

  return (
    <React.Fragment>
      {articleVideoPreview && <VideoImageSwapDialog
        open={Boolean(articleVideoPreview)}
        handleClose={handleClose}
        dialogText={articleVideoPreview.videos[0].keyword}
        article={articleVideoPreview}
        onRemoveVideo={(vid) => {
          removeVideo(articleVideoPreview.id, vid);
        }}
        onAddVideo={null}
      />}

      <Root>
        <PageToolbar
          setSearchTye={setSearchType}
          publishers={publishers}
          setPublisher={setPublisher}
          selectedPublisher={selectedPublisher}
          setVideoStatus={setVideoStatus}
          videoStatus={videoStatus}
          setDateRange={setDateRange}
          filterByDate={filterByDate}
          setIncludeExternal={setIncludeExternal}
          includeExternal={includeExternal}
        />
        { busy && <Overlay /> }
      </Root>


      <Root>
        {busy && items && <LinearProgress className={'linear-top'} color="primary"/>}
        <Table size="small">
          <TableHead>
            <TableRowPrimary>
              <TableCellHead padding={'none'}/>
              <TableCellHead>
                VIDEO
              </TableCellHead>
              <TableCellHead>
                TITLE
              </TableCellHead>
              <TableCellHead>
                TRENDING TOPIC
              </TableCellHead>
              <TableCellHead>
                PRODUCER
              </TableCellHead>
              <TableCellHead>
                SCORE
              </TableCellHead>
              <TableCellHead>
                PLAYS
              </TableCellHead>
              <TableCellHead>
                COMPLETE
              </TableCellHead>
              <TableCellHead>
                COST
              </TableCellHead>
              <TableCellHead>
                TOOLS
              </TableCellHead>
            </TableRowPrimary>
          </TableHead>
          <TableBody>
            {!items && busy && <TableRowPrimary><TableCellHead colSpan={9} align={'center'}><Box
              style={{ padding: 20 }}><CircularProgress/></Box></TableCellHead></TableRowPrimary>}
            {items &&
              items.map((item, index) => {
                const key = item.has_video ?  item.video_id + item.article.id : item.keyword
                return (<Rows
                  key={key}
                  index={index}
                  loadingId={loadingId}
                  setArticleVideoPreview={setArticleVideoPreview}
                  addVideo={createPost}
                  item={item}
                  previewIndex={previewIndex}
                  setPreviewIndex={setPreviewIndex}
                  showType={videoStatus === 'all'}
                />);
              })}
          </TableBody>
        </Table>
        {busy && items && <LinearProgress className={'linear-bottom'} color="primary"/>}
        <PaginationTable
        busy={busy}
        defaultSize={50}
        startPage={pagination.currentPage - 1}
        count={pagination.totalItems}
        onChange={(page) => dispatch({
          type: 'SET_PAGINATION', payload: {
            currentPage: page + 1
          }
        })}
        updateRowPerPage={(totalPerPage) => {
          dispatch({
            type: 'SET_PAGINATION', payload: {
              currentPage: 1,
              itemsPerPage: totalPerPage
            }
          });
        }}
      />
      </Root>
    </React.Fragment>
  );
}
