import { useContext, useState } from 'react';
import axios from 'axios';
import { FormControl, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Headline, Paragraph } from '../common/PublicTypography';
import { InputLabel, InputText, BaseButton } from '../common/Form';
import { getApiBaseUrl } from '../../utils/env';
import { CalculatorContext } from '../../context/calculator/CalculatorProvider';

const SubmitBtn = styled(BaseButton)`
  ${props => props.theme.breakpoints.up('md')} {
    margin-bottom: 40px;
  }
`;

const Form = styled('form')`
  background: #fff;
  margin-top: ${({ theme }) => theme.spacing(3.5)};
  padding: ${({ theme }) => theme.spacing(2.5)};
  border-radius: 9px;
  color: #2E434E;
  text-align: left;
  
  ${props => props.theme.breakpoints.up('md')} {
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ContactForm = ({ className }) => {
  const { calc } = useContext(CalculatorContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [isFormSent, setIsFormSent] = useState(false);

  const submitForm = (payload) => {
    axios
      .post(
        `${getApiBaseUrl()}/enquiry`,
        payload,
      ).then(response => {
        if (response.status === 201) {
          setIsFormSent(true);
          setName('');
          setEmail('');
          setPhone('');
          setWebsiteUrl('');
        }

    })
  }
  return (
    <Grid container className={className}>
      <Grid item xs={12} justifyContent={'center'} textAlign={'center'} sx={{ paddingTop: 9.375 }}>
        <Headline variant={'h1'}>Contact us today</Headline>
        <Paragraph variant={'body1'}>We’ll quickly let you know where and how you can generate
          revenue</Paragraph>
        <Form onSubmit={(e) => {
          e.preventDefault();
          submitForm({
            name,
            email,
            phone,
            websiteUrl,
            dailyPageviews: calc?.dailyPageviews || null,
            matchPercentage: calc?.matchPercentage || null,
            currentAverageCpm: calc?.autoCpm || null,
            isAutoplay: calc?.isCtp !== undefined ? String(!calc.isCtp) : null,
            fillPercentage: calc?.fillRatePercentage || null,
            includeHeaderbidding: calc?.includeHeaderbidding !== undefined ? String(calc.includeHeaderbidding) : null,
            isProgrammatic: calc?.isProgrammatic ? String(calc.isProgrammatic) : null,
            percentagePagesMissingVideo: calc?.imageToVideoRatioPercentage || null
          })
        }}>
          <Grid container rowSpacing={2} spacing={3}>
            <Grid item md={6} xs={12}>
              <FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="name">Name*</InputLabel>
                <InputText id={'name'}
                           onChange={({ target }) => setName(target.value)}
                           required
                           value={name}
                           placeholder={'Your Name'}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="email">Email*</InputLabel>
                <InputText id={'email'}
                           onChange={({ target }) => setEmail(target.value)}
                           required
                           value={email}
                           placeholder={'Your Email'}
                           type={'email'}
                />
              </FormControl>
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="phone">Phone (optional)</InputLabel>
                <InputText id={'phone'}
                           onChange={({ target }) => setPhone(target.value)}
                           placeholder={'Your Phone Number'}
                           value={phone}
                           type={'number'}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="websiteInQuestion">Website in Question*</InputLabel>
                <InputText id={'websiteInQuestion'}
                           required
                           placeholder={'your-website.com'}
                           value={websiteUrl}
                           onChange={({ target }) => setWebsiteUrl(target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <SubmitBtn type={'submit'} disabled={isFormSent} variant={'contained-x'} fullWidth>{isFormSent ?
                'We’ll be in touch within 24 hours' : 'Request callback'}</SubmitBtn>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Grid>);
};

ContactForm.propTypes = {
  className: PropTypes.string,
}

export default styled(ContactForm)``;
