import { Box, Drawer, GlobalStyles, Grid, ListItemButton, Typography } from '@mui/material';
import Helmet from 'react-helmet';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@mui/material/ListItemText';

import '../../App.css';
import './PublicLayout.css';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import logoWhite from '../../pixels-ai-logo-white.svg';
import logo from '../../pixels-ai-logo.svg';
import MenuIcon from '../../icons/Menu';
import { useState } from 'react';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight';

const LogoAnchor = styled('a')`
  display: flex;
  align-items: center;
`;

const Logo = styled('span')`
  background-image: url(${logo});
  width: 166px;
  height: 25px;
  display: inline-block;
  background-repeat: no-repeat;
  text-indent: -9999px;
`;

const LogoHeader = styled(Logo)`
  ${props => props.theme.breakpoints.up('md')} {
    width: 145px;
    height: 22px;
    background-image: url(${logoWhite});
  }
`;



const LogoDrawer = styled('span')`
  width: 145px;
  height: 22px;
  background-image: url(${logoWhite});
  display: inline-block;
  background-repeat: no-repeat;
  text-indent: -9999px;
`

const ContentRoot = styled(Box)`
  flex: 1;
  ${props => props.theme.breakpoints.up('md')} {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;

const ContentInner = styled(Box)`
  padding-left: ${({ theme }) => theme.spacing(2.6) };
  padding-right: ${({ theme }) => theme.spacing(2.6) };
  ${props => props.theme.breakpoints.up('md')} {
    max-width: 800px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;

const HeaderGrid = styled(Grid)`
  background-color: #fff;
  ${props => props.theme.breakpoints.up('md')} {
    background-color: inherit;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const FooterGrid = styled(Grid)`
  ${props => props.theme.breakpoints.up('md')} {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: initial;
  height: 100%;
  margin: 0 auto;
  min-height: 100vh;
`;

const Menu = styled('ul')`
  margin: 0;
  font-size: 14px;
  font-family: ProximaNovaSemiBold;
  padding: 0;
`;

const TopMenu = styled(Menu)`
  display: none;
  ${props => props.theme.breakpoints.up('md')} {
    display: flex;
  }
`

const MenuItem = styled('li')`
  list-style: none;
  font-family: ProximaNovaLight;
  
  a {
    display: block;
    padding: 8px 6px;
    text-decoration: none;
    color: #21316C;
    &:visited, &:active, &:hover {
      color: #21316C;
    }
    ${props => props.theme.breakpoints.up('md')} {
      color: white;
      padding: 8px 12px;
      &:visited, &:active, &:hover {
        color: white;
      }
    }
  }
`;

const FooterMenu = styled(Menu)`
  display: flex;
  ${props => props.theme.breakpoints.up('md')} {
    justify-content: center;
  }
`;

const FooterMenuItem = styled(MenuItem)`
  position: relative;
  font-family: ProximaNovaLight, Roboto, 'sans-serif';
  font-size: 12px;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 22px;
  }
  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: #000;
    opacity: 0.1;
  }
  &:last-child:after {
    display: none;
  }
  a {
    color: #21316C;
    ${props => props.theme.breakpoints.up('md')} {
      &:visited, &:active, &:hover {
        color: #21316C;
      }
    }
  }
`

const Footer = styled('footer')`
  position: relative;
  background-color: #fff;
  color: #21316C;
  &:before {
    content: ' ';
    position: absolute;
    width: calc(100%);
    height: 1px;
    background-color: #F0F6FF;
  }
`

const DrawerText = styled(ListItemText)`
  font-size: 18px;
`

const DrawerRowAnchor = styled(Box)`
  position: relative;
  
  &.initial {
    &:before {
      content: ' ';
      position: absolute;
      width: calc(100% + 32px);
      height: 1px;
      left: -16px;
      top: 0;
      opacity: 0.1;
      background-color: #fff;
    }
  }
  &:after {
    content: ' ';
    position: absolute;
    width: calc(100% + 32px);
    height: 1px;
    left: -16px;
    bottom: 0;
    opacity: 0.1;
    background-color: #fff;
  }
`

const Copyright = styled(Typography)`
  font-size: 12px;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 22px;
  }
`

const ListMobileAnchor = ({ children, ...rest }) => (<ListItemButton component={'a'} {...rest}>{children}</ListItemButton>)

function PublicLayout({ children, className }) {
  const [drawerState, setDrawerState] = useState(false);

  return (
    <Root className={className}>
      <GlobalStyles styles={{ body: { backgroundColor: '#0B2954', color: '#fff' } }} />
      <Helmet>
        <meta charSet="UTF-8"/>
      </Helmet>
      <header>
        <HeaderGrid p={2} container spacing={{ xs: 0 }} justifyContent={'space-between'}
              alignItems={'center'}>
          <Grid item>
              <LogoAnchor href={'/'}>
                <LogoHeader>Pixels AI</LogoHeader>
              </LogoAnchor>
          </Grid>
          <Grid item>
            <MenuIcon onClick={() => setDrawerState(true)}/>
            <TopMenu>
              <MenuItem><a href={'/video-revenue-calculator/'}>Video Revenue Calculator</a></MenuItem>
              <MenuItem><a href={'/contact/'}>Contact</a></MenuItem>
              <MenuItem><a href={'/login/'}>Login</a></MenuItem>
            </TopMenu>
          </Grid>
        </HeaderGrid>
      </header>
      <ContentRoot className={'content-root'}>
        <ContentInner className={'content-inner'}>
          {children}
        </ContentInner>
      </ContentRoot>
      <Footer>
        <FooterGrid p={2} sx={theme => ({
          [theme.breakpoints.up('md')]: {
            pt: 6.25,
            pb: 5,
          }
        })} container flexDirection={'row'} alignItems={'center'}>
          <Grid item display={{ md: "block", xs: 'none' }}>
              <LogoAnchor href={'/'}>
                <Logo>Pixels AI</Logo>
              </LogoAnchor>
          </Grid>
          <Grid item flex={1} justifyContent={{ md: 'center', xs: ''}}>
            <FooterMenu>
              <FooterMenuItem><a href={'/contact/'}>Contact us</a></FooterMenuItem>
              <FooterMenuItem><a href={'/privacy-policy/'}>Privacy policy</a></FooterMenuItem>
            </FooterMenu>
          </Grid>
          <Grid item>
            <Copyright>&copy; Pixels AI Ltd. {new Date().getFullYear()}</Copyright>
          </Grid>
        </FooterGrid>
      </Footer>
      <Drawer
        PaperProps={{
          sx: {
            width: '100%',
            backgroundColor: '#0B2954',
            color: '#fff',
            fontSize: 18,
          },
        }}
        anchor="right"
        open={drawerState}
        onClose={() => setDrawerState(false)}
      >
        <Box
          sx={{
            p: 2,
            height: 1,
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1
          }}>
            <IconButton onClick={() => setDrawerState(false)}>
              <CloseIcon htmlColor={'#fff'} />
            </IconButton>
            <Box sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center'
            }}>
              <a href={'/'}><LogoDrawer>Pixels.AI</LogoDrawer></a>
            </Box>
          </Box>
          <DrawerRowAnchor className={'initial'}>
            <ListMobileAnchor href={'/video-revenue-calculator/'}>
              <DrawerText primary="Video Revenue Calculator" primaryTypographyProps={{
                fontFamily: 'ProximaNovaSemiBold'
              }}/>
              <ArrowRight />
            </ListMobileAnchor>
          </DrawerRowAnchor>
          <DrawerRowAnchor>
            <ListMobileAnchor href={'/contact/'}>
              <DrawerText primary="Contact" primaryTypographyProps={{
                fontFamily: 'ProximaNovaSemiBold'
              }}/>
              <ArrowRight />
            </ListMobileAnchor>
          </DrawerRowAnchor>
          <DrawerRowAnchor>
            <ListMobileAnchor href={'/login/'}>
              <DrawerText primary="Login" primaryTypographyProps={{
                fontFamily: 'ProximaNovaSemiBold'
              }} />
            </ListMobileAnchor>
          </DrawerRowAnchor>
        </Box>
      </Drawer>
    </Root>
  );
}

PublicLayout.propTypes = {
  className: PropTypes.string,
};

export default styled(PublicLayout)`
`;
