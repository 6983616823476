import * as React from 'react';
import {
  Checkbox,
  FormControl, FormControlLabel,
  Grid, TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Heading } from './common/Heading';
import Button from '@mui/material/Button';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Root = styled('div')`
  position: relative;
  .linear-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  .linear-top {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
`;

export default function PublishersAdd() {
  const { orgSlug } = useParams();
  const isAdmin = localStorage.getItem('is_admin') === 'true';
  if (!isAdmin) {
    window.location.href = '/';
  }

  const [data, setData] = useState({
    is_demo: true,
    name: '',
    domain: '',
    hostname: '',
    rss_feed_url: '',
    video_tag_patterns: '',
    image_tag_pattern: '',
    crawl_article_size_per_minute: 1,
    crawl_article_total: 50,
    crawl_current_article_count: 0,
    is_active: true
  });
  const changeData = (key) => ({ target: { value } }) => setData({
    ...data,
    [key]: value
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const action = orgSlug ? 'patch' : 'post';
    const payload: any = data;
    if (orgSlug) {
      payload.domain = orgSlug;
    }
    axios[action](
      `${getApiBaseUrl()}/organisation/demo`,
      payload,
    ).then(() => {
      alert('Publisher is added successfully');
    }).catch((ex) => {
      const { message } = ex.response?.data;
      if (Array.isArray(message) && message.length > 0) {
        alert(message.join('\n'));
      }
    });
  };

  const updatePublisher = () => {
    axios.get(
      `${getApiBaseUrl()}/organisation/demo/${orgSlug}`,
    ).then(({ data: orgDemo }) => {
      if (orgDemo) {
        setData({
          ...data,
          ...orgDemo
        });
      }
    }).catch(ex => {
      if (ex.message) {
        alert(ex.message);
      }
      console.error(ex);
    });
  };

  useEffect(() => {
    if (orgSlug) {
      updatePublisher();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgSlug]);

  return (
    <React.Fragment>
      <Root>
        <Grid container style={{ paddingBottom: '15px', paddingLeft: '15px' }}>
          <Grid container alignItems="center" justifyContent={'space-between'}>
            <Grid item>
              <Heading>{orgSlug ? 'Edit demo publisher' : 'Add demo publisher'}</Heading>
            </Grid>
          </Grid>
        </Grid>
      </Root>
      <Root>

        <form onSubmit={handleSubmit} style={{ width: 600 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  name="name"
                  value={data.name}
                  label="Publisher Name"
                  variant="outlined"
                  placeholder={'eg. Example News'}
                  onChange={changeData('name')}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  name="domain"
                  value={data.domain}
                  label="Publisher Slug"
                  variant="outlined"
                  placeholder={'eg. examplenews'}
                  onChange={changeData('domain')}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  name="hostname"
                  value={data.hostname}
                  label="Publisher hostname"
                  variant="outlined"
                  placeholder={'eg. https://www.examplenews.co.uk'}
                  onChange={changeData('hostname')}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  name="rss_feed_url"
                  value={data.rss_feed_url}
                  label="RSS Feed URL"
                  variant="outlined"
                  placeholder={'eg. https://www.examplenews.co.uk/sitemap/rss'}
                  onChange={changeData('rss_feed_url')}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type={'number'}
                  fullWidth
                  value={data.crawl_article_total}
                  name="crawl_article_total"
                  label="Total article to crawl"
                  variant="outlined"
                  onChange={changeData('crawl_article_total')}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type={'number'}
                  fullWidth
                  value={data.crawl_article_size_per_minute}
                  name="crawl_article_size_per_minute"
                  label="Total article to crawl per minute "
                  variant="outlined"
                  onChange={changeData('crawl_article_size_per_minute')}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  name="video_tag_patterns"
                  value={data.video_tag_patterns}
                  label="Hero video selector"
                  placeholder={'Comma separated css selector'}
                  variant="outlined"
                  onChange={changeData('video_tag_patterns')}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  name="image_tag_pattern"
                  value={data.image_tag_pattern}
                  label="Hero image selector"
                  placeholder={'Comma separated css selector'}
                  variant="outlined"
                  onChange={changeData('image_tag_pattern')}
                />
              </FormControl>
            </Grid>

            {orgSlug && <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type={'number'}
                  fullWidth
                  name="crawl_current_article_count"
                  value={data.crawl_current_article_count}
                  label="Current article count"
                  placeholder={'Reset the total number of article to be scrapped'}
                  variant="outlined"
                  onChange={changeData('crawl_current_article_count')}
                />
              </FormControl>
            </Grid>}

            {orgSlug && <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox onChange={({ target: { checked } }) => {
                  setData({
                    ...data,
                    is_active: checked
                  });
                }}
                                   checked={data.is_active}/>}
                label="Active"/>
            </Grid>}

            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button href="/publishers" variant="outlined">Cancel</Button>
                </Grid>
                <Grid item>
                  <Button color="primary" variant="contained" type={'submit'}>Submit</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Root>
    </React.Fragment>
  );
};
