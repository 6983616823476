import {
  FormControl,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const RootText = styled(Box)`
  display: flex;
  gap: 1; 
  min-height: 24px;
  font-family: 'ProximaNovaSemiBold';
  color: rgba(0, 0, 0, 0.87);
  align-items: center;
  > img {
    padding-right: 6px;
  }
`;

const PubAvatarRoot = styled(Box)`
  display: flex;
  align-items: center;
  height: 43px;
  border-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
`;

const LogoRoot = styled('span')`
  padding-right: 6px;
  display: flex;
`;

export const PublicationSelector = ({ publishers, onChange, selectedPublication }) => {
  const publisher = publishers.find(({ domain }) => domain === selectedPublication);

  if (publishers.length === 0) return null;

  if (publishers.length === 1) {
    const [pub] = publishers;
    return (
      <PubAvatarRoot>
        <LogoRoot><img
          width="24"
          src={`/logo/${publisher.domain}.png`}
          alt={`${publisher.name} logo`}
        /></LogoRoot>
        &nbsp; {pub.name}
      </PubAvatarRoot>
    );
  }

  return (
    <FormControl sx={{ m: 1, minHeight: 42, minWidth: 220 }} size="small">
      <Select
        margin={'dense'}
        displayEmpty
        onChange={(e: SelectChangeEvent) => {
          e.preventDefault();
          onChange(e.target.value);
        }}
        defaultValue={selectedPublication}
        renderValue={(value) => {
          return (
            <RootText>
              <img width="24" src={`/logo/${publisher.domain}.png`}
                   alt={`${value} logo`}/>
              {value}
            </RootText>
          );
        }}
      >
        {publishers.map(({ domain, name }) => {
          return (
            <MenuItem key={domain} value={domain}>
              <ListItemIcon>
                <LogoRoot><img
                  width="24"
                  src={`/logo/${domain}.png`}
                  alt={name}
                /></LogoRoot>
                {domain}
              </ListItemIcon>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};