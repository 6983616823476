import { Button, Grid } from '@mui/material';
import * as React from 'react';
import { PublicationSelector } from './PublicationSelector';
import { useEffect, useState } from 'react';
import { Heading } from './common/Heading';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Moment from 'react-moment';
import { TableCellHead, TableCellBody, TableCellBodyLight } from './common/Table';
import { BoldTypography, BoldTypographyLarge } from './common/TextLabel';
import { JWPlayerPreview } from './modal/JWPlayerPreview';
import { VideoThumbnail } from './common/VideoThumbnail';
import { ReactComponent as PencilIcon } from '../icons/pencil.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { EditBrandedContentDialog } from './modal/EditBrandedContentDialog';
import { Countries } from '../constant/countries';

const TableRoot = styled(Grid)`
  margin-top: 80px;
  flex-basis: 100%;
`;

const fetchBrandedContent = async (publication) => {
  return axios
    .get(
      `${getApiBaseUrl()}/video-player/${publication}/campaign`,
    ).then(({ data }) => data).catch((err) => {
      console.error(err);
      return null;
    });
};

const PacingCurrentLabel = styled('span')`
color: #4caf50;
`;

const CampaignHeading = styled(Heading)`
  margin-bottom: 20px;
`;

export const TableCellCountry = styled(TableCellBody)`
  width: 30%;
`;

const Campaigns = ({ list, previewVideoUrl, setEditContent }) => {
  const getStatusText = ({ enabled, status, startTimestamp }) => {
    if (status && enabled) {
      return 'Active';
    } else if (status && !enabled) {
      return 'Paused'
    } else if (!status && startTimestamp > (new Date().getTime() / 1000) ) {
      return 'Scheduled'
    } else {
      return 'Completed'
    }
  }
  return (<Table size="small">
    <TableHead>
      <TableRow>
        <TableCellHead>Status</TableCellHead>
        <TableCellHead>Promoted Content</TableCellHead>
        <TableCellHead>Geo Country</TableCellHead>
        <TableCellHead>Start date</TableCellHead>
        <TableCellHead>Pacing</TableCellHead>
        <TableCellHead>End date</TableCellHead>
        <TableCellHead>Tools</TableCellHead>
      </TableRow>
    </TableHead>
    <TableBody>
      {list.map((line) => (<TableRow key={line.sk}>
        <TableCellBody><BoldTypography>{getStatusText(line)}</BoldTypography></TableCellBody>
        <TableCellBodyLight>
          <Grid container flexDirection={'row'} alignItems={'center'} spacing={2}>
            <Grid item xs={3}
                  onClick={() => previewVideoUrl(`https://cdn.jwplayer.com/v2/media/${line.media.mediaid}`)}>
              <VideoThumbnail videoCount={null} imageUrl={line.media.images[0].src}/>
            </Grid>
            <Grid item xs={9}>
              {line.media.title}
            </Grid>
          </Grid>
        </TableCellBodyLight>
        <TableCellCountry>
          {line.geoCountryCode.length > 0 &&
            <p><BoldTypographyLarge>Include: {line.geoCountryCode.length === Countries.length ? 'All countries' : line.geoCountryCode.join(', ')}</BoldTypographyLarge></p>}
          {line.geoCountryCodeExclusion.length > 0 &&
            <BoldTypographyLarge>Exclude: {line.geoCountryCodeExclusion.length === Countries.length ? 'All' +
              ' countries' : line.geoCountryCodeExclusion.join(', ')}</BoldTypographyLarge>}
        </TableCellCountry>
        <TableCellBody><BoldTypographyLarge><Moment
          format="Do MMM YYYY">{line.startTimestamp * 1000}</Moment></BoldTypographyLarge></TableCellBody>
        <TableCellBody><BoldTypographyLarge><PacingCurrentLabel
          title={`daily target: ${line.dailyImpressionTarget}`}>{line.eventVideoStartPlaying}</PacingCurrentLabel> / {line.totalImpressionTarget}
        </BoldTypographyLarge></TableCellBody>
        <TableCellBody><BoldTypographyLarge><Moment
          format="Do MMM YYYY">{line.endTimestamp * 1000}</Moment></BoldTypographyLarge></TableCellBody>
        <TableCellBody>
          <SvgIcon style={{ cursor: 'pointer' }} onClick={() => setEditContent(line)}>
            <PencilIcon/>
          </SvgIcon>
        </TableCellBody>
      </TableRow>))}
    </TableBody>
  </Table>);
};

export default function PromotedContent({
  setPublisher,
  publishers,
  selectedPublisher
}) {
  const [campaigns, setCampaigns] = useState([]);
  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [editContent, setEditContent] = React.useState<any>(null);
  const nowTimestamp = Math.floor(new Date().getTime() / 1000);
  const initialCampaign = {
    enabled: true,
    videoId: '',
    pathInclude: ['*'],
    pathExclude: [],
    totalImpressionTarget: 0,
    geoCountryCode: [],
    geoCountryCodeExclusion: [],
    startTimestamp: nowTimestamp,
    endTimestamp: nowTimestamp,
    priority: 0,
    dailyImpressionTarget: 0,
    orgSlug: selectedPublisher,
  };

  const saveBrandedContent = (data) => {
    const action = data.sk ? 'patch' : 'post';
    axios[action](
        `${getApiBaseUrl()}/promoted-content`,
        data,
      ).then(() => {
      setEditContent(false);
      fetchBrandedContent(selectedPublisher).then(setCampaigns);
    }).catch((ex) => {
      const { message } = ex.response?.data;
      if (Array.isArray(message) && message.length > 0) {
        alert(message.join('/n'))
      }
    });
  };

  useEffect(() => {
    if (!videoUrl) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [videoUrl]);

  useEffect(() => {
    fetchBrandedContent(selectedPublisher).then(setCampaigns);
  }, [selectedPublisher]);

  return (
    <React.Fragment>
      <JWPlayerPreview open={open} handleClose={() => setOpen(false)} videoUrl={videoUrl}/>
      <Grid container style={{ paddingBottom: '15px', paddingLeft: '15px' }}>
        <Grid container alignItems="center" justifyContent={'space-between'}>
          <Grid item>
            <Heading>Promoted Content</Heading>
          </Grid>
          <Grid item xs={2}>
            <PublicationSelector
              selectedPublication={selectedPublisher}
              publishers={publishers}
              onChange={setPublisher}
            />
          </Grid>
        </Grid>
        <TableRoot>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <CampaignHeading>Campaigns</CampaignHeading>
            </Grid>
            <Grid item>
              <Button variant={'contained'} onClick={() => setEditContent((initialCampaign))}>Add new</Button>
            </Grid>
          </Grid>
          {campaigns && <Campaigns setEditContent={setEditContent} previewVideoUrl={setVideoUrl}
                                   list={campaigns}/>}
        </TableRoot>
      </Grid>
      {editContent && <EditBrandedContentDialog
        data={editContent}
        handleClose={() => setEditContent(null)}
        open={Boolean(editContent)}
        onSave={saveBrandedContent}
      />}
    </React.Fragment>
  );
}
