import Login from './components/public/Login';
import Dashboard from './components/Dashboard';

import ApiClientProvider from './api/apiClientProvider';
import { createApiClient } from './api/rootClient';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { getApiBaseUrl } from './utils/env';
import PrivacyPolicy from './components/PrivacyPolicy';
import axios from 'axios';
import Home from './components/public/Home';
import RevenueLossCalculator from './components/public/RevenueLossCalculator';
import Contact from './components/public/Contact';

const apiClient = createApiClient(getApiBaseUrl());

axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401 && window.localStorage) {
      localStorage.setItem('token', '');
      if (!window.location.pathname.startsWith('/login')) {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error.response.data.message ?? error);
  }
);

function App() {
  return (
    <ApiClientProvider value={apiClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
          <Route path="/privacy" element={<Navigate to={'/privacy-policy'} />} />
          <Route path="/" element={<Home />} />
          <Route path="/video-revenue-calculator/" element={<RevenueLossCalculator />} />
          <Route path="/contact/" element={<Contact />} />
          <Route path="/login/" element={<Login />} />
          <Route
            path="/dashboard/top-stories"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="keywords" />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/video-swap"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="video-swap" />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/video-swap/swapped"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="video-swap-swapped" />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/video-swap/improve"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="video-swap-improve" />
              </RequireAuth>
            }
          />
          <Route
            path="/video-swap"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard demoPage section="video-swap" />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/generate-article"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="generate-article" />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/calculator"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="calculator" />
              </RequireAuth>
            }
          />
          <Route
            path="/calculator"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard demoPage section="calculator" />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/promoted-content"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="promoted-content" />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/smart-library"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="smart-library" />
              </RequireAuth>
            }
          />
          <Route
            path="/publishers"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="publishers" />
              </RequireAuth>
            }
          />
          <Route
            path="/publishers/add"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="publishers-add" />
              </RequireAuth>
            }
          />
          <Route
            path="/publishers/edit/:orgSlug"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="publishers-edit" />
              </RequireAuth>
            }
          />
          <Route
            path="/publishers/:orgSlug/articles"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="publishers-articles" />
              </RequireAuth>
            }
          />
          <Route
            path="/users"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="users" />
              </RequireAuth>
            }
          />
          <Route
            path="/users/add"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="user-add" />
              </RequireAuth>
            }
          />
          <Route
            path="/users/edit/:id"
            element={
              <RequireAuth navigateTo="/login">
                <Dashboard section="user-edit" />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </ApiClientProvider>
  );
}

function RequireAuth({ children, navigateTo }) {
  const token = window.localStorage && window.localStorage.getItem('token');
  return token ? children : <Navigate to={navigateTo} />;
}

export default App;
