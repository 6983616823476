import * as React from 'react';
import { decode } from 'he';
import {
  Checkbox,
  Grid,
  TextField, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Heading } from './common/Heading';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { TableRowPrimary } from './SmartLibrary';
import { TableCellBody, TableCellHead } from './common/Table';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import { Link } from './common/Link';

const ActionRoot = styled(Grid)`
  margin-bottom: 20px;`;

const ErrorBox = styled(Grid)`
  padding: 16px;
  background-color: #eee;
`

const CodePre = styled('pre')`
  white-space: pre-wrap;
`

const Root = styled('div')`
  position: relative;
  .linear-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  .linear-top {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
`;

type Article = {
  id: number;
  headline: string;
  url: string;
  thumbnail: string;
  video_id: string;
  is_video: boolean;
  is_visible_in_dashboard: boolean
  video_score: number;
}

const ArticleRow = ({
  data: article
}) => {

  const [visible, setVisible] = useState(article.is_visible_in_dashboard);
  const [videoId, setVideoId] = useState(article.video_id);
  const [videoScore, setVideoScore] = useState(article.video_score);
  const saveArticle = () => {
    axios.patch(
      `${getApiBaseUrl()}/article-demo`,
      {
        id: article.id,
        is_visible_in_dashboard: Boolean(visible),
        video_id: videoId || '',
        video_score: videoScore || null
      },
    ).then(({ data }) => {
      console.log('>>> success');
    }).catch(ex => {
      if (ex.message) {
        alert(ex.message);
      }
      console.error(ex);
    });
  };

  return (<TableRowPrimary sx={{ height: '60px', position: 'relative' }}>
    <TableCellBody>{article.thumbnail && <img alt={article.headline} style={{ maxWidth: 80 }}
                                              src={article.thumbnail}/>}</TableCellBody>
    <TableCellBody><Link className="hover-underline" target="_blank" variant="body1"
                         href={article.url}>{decode(article.headline)}</Link></TableCellBody>
    <TableCellBody align={'center'}>{article.is_video ? 'Video' : 'Image'}</TableCellBody>
    <TableCellBody align={'center'}><Checkbox checked={visible}
                                              onChange={({ target: { checked } }) => setVisible(checked)}/></TableCellBody>
    <TableCellBody align={'center'}><TextField defaultValue={videoId}
                                               onChange={({ target: { value } }) => setVideoId(value)}/></TableCellBody>
    <TableCellBody align={'center'}><TextField type={'number'} defaultValue={videoScore}
                                               onChange={({ target: { value } }) => setVideoScore(value)}/></TableCellBody>
    <TableCellBody align={'center'}><Button variant={'outlined'}
                                            onClick={saveArticle}>Update</Button></TableCellBody>
  </TableRowPrimary>);
};

export default function PublishersDemoArticles() {
  const { orgSlug } = useParams();
  const isAdmin = localStorage.getItem('is_admin') === 'true';
  if (!isAdmin) {
    window.location.href = '/';
  }

  const [articles, setArticles] = useState<Article[]>([]);
  const [progressText, setProgressText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [orgName, setOrgName] = useState('');

  const handleUpdateArticles = () => {
    axios.patch(`${getApiBaseUrl()}/organisation/demo/${orgSlug}/update-counter`, {}).then(getArticles);
  };

  const handleDelete = () => {
    if (!window.confirm('Are you sure you want to remove the existing articles?')) {
      return;
    }
    axios.delete(
      `${getApiBaseUrl()}/article-demo/${orgSlug}`,
    ).then(getArticles);
  };

  const resetErrorLog = () => {
    axios.patch(
      `${getApiBaseUrl()}/article-demo/reset-error-log/${orgSlug}`,
    )
  };

  const getArticles = () => {
    axios.get(
      `${getApiBaseUrl()}/article-demo/${orgSlug}/articles`,
    ).then(({ data }) => {
      setArticles(data.articles);
      if (data.crawl_current_article_count < data.crawl_article_total) {
        setProgressText(`Currently importing ${data.crawl_current_article_count} out of ${data.crawl_article_total} articles`)
      } else {
        setProgressText('Import completed')
      }
      setErrorMessage(data.crawl_error_log)
    }).catch(ex => {
      if (ex.message) {
        alert(ex.message);
      }
      console.error(ex);
    });
  };

  useEffect(() => {
    if (orgSlug) {
      getArticles();
      axios.get(
        `${getApiBaseUrl()}/organisation/demo/${orgSlug}`,
      ).then(({ data }) => {
        setOrgName(data.name);
      }).catch(ex => {
        if (ex.message) {
          alert(ex.message);
        }
        console.error(ex);
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgSlug]);

  return (
    <React.Fragment>
      <Root>
        <Grid container
              style={{ paddingBottom: '15px', paddingLeft: '15px', alignItems: 'stretch' }}>
          <Grid container alignItems="stretch" justifyContent={'space-between'}>
            <Grid item>
              {orgName && <Heading>{orgName} demo articles</Heading>}
            </Grid>
            <Grid item>
              { progressText && <p>{progressText}</p>}
            </Grid>
          </Grid>
        </Grid>
      </Root>
      <Root>
        <ActionRoot container spacing={2} justifyContent={'space-between'}>
          <Grid item>
            <Button href={'/publishers'} variant={'outlined'}>Back</Button>
          </Grid>
          <Grid item>
            <Button onClick={resetErrorLog} variant={'outlined'}>Reset error log</Button>
          </Grid>
        </ActionRoot>
        { errorMessage && <ErrorBox><Typography color={'error'}>Error message found on the log.</Typography><CodePre>{errorMessage}</CodePre></ErrorBox>}
        { articles.length > 0 && <Table sx={{ marginBottom: 2 }} size="small">
          <TableHead>
            <TableRowPrimary>
              <TableCellHead>Thumbnail</TableCellHead>
              <TableCellHead>Headline</TableCellHead>
              <TableCellHead align={'center'}>Article Type</TableCellHead>
              <TableCellHead align={'center'}>Show in dashboard</TableCellHead>
              <TableCellHead align={'center'}>Video Id</TableCellHead>
              <TableCellHead align={'center'}>Video Score</TableCellHead>
              <TableCellHead align={'center'}>Action</TableCellHead>
            </TableRowPrimary>
          </TableHead>
          <TableBody>
            {articles.map((article) => <ArticleRow key={article.id} data={article}/>)}
          </TableBody>
        </Table> }
        {articles.length > 0 && (<ActionRoot container spacing={2} justifyContent={'space-between'}>
          <Grid item>
            <Button onClick={handleUpdateArticles} variant={'outlined'}>Run again</Button>
          </Grid>
          <Grid item>
            <Button onClick={handleDelete} color={'error'} variant={'contained'}>Remove all and run
              again</Button>
          </Grid>
        </ActionRoot>)}
      </Root>
    </React.Fragment>
  );
}
