import React from 'react';
import { hydrate, render } from 'react-dom';

import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import imageSwap from './store/imageSwapSlice';
import keyword from './store/keywordSlice';
import smartLibrary from './store/smartLibrarySlice';

import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CalculatorProvider } from './context/calculator/CalculatorProvider';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'contained-x': true;
    'outlined-x': true;
  }
}

const middleware = [thunk];

const appReducers = combineReducers({
  imageSwap,
  keyword,
  smartLibrary
});

const store = createStore(appReducers, composeWithDevTools(
  applyMiddleware(...middleware),
));

const theme = createTheme({
  typography: {
    fontFamily: [
      'ProximaNovaLight',
      'Roboto',
      'sans-serif',
    ].join(','),
    h1: { fontFamily: 'ProximaNovaSemiBold' },
    h2: { fontFamily: 'ProximaNovaSemiBold' },
    h3: { fontFamily: 'ProximaNovaSemiBold' },
    h4: { fontFamily: 'ProximaNovaSemiBold' },
    h5: { fontFamily: 'ProximaNovaSemiBold' },
    h6: { fontFamily: 'ProximaNovaSemiBold' },
    body1: {},
  },
  components: {
    MuiTypography: {
      defaultProps: {},
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained-x' },
          style: {
            background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2)' +
              ' 100%), #3A54CA',
            backgroundBlendMode: 'overlay, normal',
            color: 'white',
            border: '1px solid #3A54CA',
            '&:hover': {
              background: '#3A54CA',
            },
            '&.Mui-disabled': {
              background: 'rgb(42, 131, 226, .1)',
              color: '#3355D1',
              borderColor: 'transparent'
            }
          },
        },
        {
          props: { variant: 'outlined-x' },
          style: {
            border: '1px solid transparent',
            background: 'white',
            borderRadius: 6,
            '&:hover': {
              background: 'white',
            },
          },
        },
      ]
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 63,
          height: 36.5,
          padding: 0,
        },
        switchBase: {
          color: '#3A54CA',
          padding: 0,
          margin: 3,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(27px)',
          }
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: '#3A54CA',
          }
        },
        thumb: {
          width: 30,
          height: 30
        },
        track: {
          opacity: 1,
          backgroundColor: '#fff',
          border: 0,
          borderRadius: 17,
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            backgroundColor: '#fff'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#1976d2'
    },
    text: {
      primary: '#212D5A'
    }
  }
});

const rootElement = document.getElementById('root');

function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
}

const Root = () => {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }
  return (<ThemeProvider theme={theme}>
  <Provider store={store}>
    <CalculatorProvider>
      <App/>
    </CalculatorProvider>
  </Provider>
</ThemeProvider>)
};

if (rootElement?.hasChildNodes()) {
  hydrate(<Root/>, rootElement);
} else {
  render(<Root/>, rootElement);
}