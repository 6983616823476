import PublicLayout from './PublicLayout';
import { styled } from '@mui/material/styles';
import Helmet from 'react-helmet';
import { GlobalStyles, Grid, Typography } from '@mui/material';

import ListItemIcon from '../../icons/list-item-icon.svg';
import PropTypes from 'prop-types';
import { BaseButton } from '../common/Form';
import BrandedCarousel from '../BrandCarousel';
import { ContentWrap, GridCenterAligned, GridContentItem } from '../common/GridLayout';
import { BaseTypography, Headline } from '../common/PublicTypography';

const SubmitBtn = styled(BaseButton)`
  ${props => props.theme.breakpoints.up('md')} {
    margin-bottom: 40px;
  }
`;

const Subheadline = styled(BaseTypography)`
  line-height: 1.2;
  margin: 24px 0;
`;

const ListRoot = styled('ul')`
  line-height: 1.2;
  margin: 16px 0;
  list-style: none;
  padding: 0;
  
  ${props => props.theme.breakpoints.up('md')} {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const ListItem = styled('li')`
  position: relative;
  padding-left: 30px;
  margin: 0;

  &:before {
    content: '';
    position: absolute;
    top: 12px;
    transform: translateY(-50%);
    left: 0;
    height: 20px;
    width: 20px;;
    background-image: url(${ListItemIcon});
    ${props => props.theme.breakpoints.up('md')} {
      top: 15px;
    }
  }
`;

const TextUnderline = styled('span')`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: #2571DF;
  }
`;

const VideoProviderText = styled(Typography)`
  font-size: 22px;
  font-family: ProximaNovaBold;
  color: #fff;
  display: none;
  ${props => props.theme.breakpoints.up('md')} {
    display: block;
    margin-bottom: 16px;
  }
`

const VideoProviderDesktopImageRoot = styled('div')`
  margin-bottom: 16px;
  ${props => props.theme.breakpoints.up('md')} {
    margin-bottom: 32px;
  }
  img {
    max-width: 100%;
  }
`

const VideoProviderMobileImageRoot = styled('div')`
  position: absolute;
  bottom: 0;
  padding: 10px 60px;
  background-color: #0B2954;
  img {
    width: 100%;
  }
`

const ShowcaseImage = styled('div')`
  width: 100%;
  aspect-ratio: 1 / .75;
  position: relative;
  overflow: hidden;
  ${props => props.theme.breakpoints.up('md')} {
    overflow: visible; 
    height: 530px;
    flex: 1;
  }
  
  &:after {
    content: '';
    background-image: url(/home1-mobile.png);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    background-size: 126%;
    background-position: -50px -42px;
    background-repeat: no-repeat;
    z-index: 0;

    ${props => props.theme.breakpoints.up('md')} {
      background-image: url(/home1.png);
      top: 20px;
      height: calc(100% + 20px);
      background-size: 90%;
      width: 200%;
      background-position: -97px -46px;
      background-repeat: no-repeat;
    }
  }
`;


export const GridMobileWithBackground = styled(Grid)`
  position: relative;

  &:before {
    ${props => props.theme.breakpoints.down('md')} {
      content: '';
      background-image: url('/bg-home.png');
      height: 100%;
      position: absolute;
      top: 0;
      width: calc(100vw + 30px);
      left: -30px;
      transform: scaleY(-1);
      background-size: 100% 88%;
      background-repeat: no-repeat;
    }
  }
`;

export const shadeBgStyles = (theme) => ({
    '#root > div': {
      color: '#fff',
      backgroundImage: 'none',
      [theme.breakpoints.up('md')]: {
        backgroundImage: 'url(/bg-home.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right',
        backgroundSize: '50% 100%',
      }
    }
  });
function Home({ className }) {
  return (
    <PublicLayout className={className}>
      <GlobalStyles styles={shadeBgStyles}/>
      <Helmet title="Pixels Ai - Precision Video Engine">
        <meta name="description" content="Gain full editorial control and match the most relevant videos to the top of trending articles with our world's largest editorial video library. Boost your website's traffic and engagement by delivering the best content with our video management services." />
      </Helmet>
      <GridCenterAligned container>
        <GridContentItem item xs={12} md={6}>
          <ContentWrap>
            <Grid container flexDirection={'column'} justifyContent={'center'}>
              <Grid item>
                <Headline variant={'h1'}><TextUnderline>Precision</TextUnderline> Play Engine</Headline>
                <Subheadline>Ai that amplifies premium video using your player, your ads and your content</Subheadline>
                <ListRoot>
                  <ListItem>
                    <BaseTypography>Recommend relevant videos for articles</BaseTypography>
                  </ListItem>
                  <ListItem>
                    <BaseTypography>Better engagement and higher revenues</BaseTypography>
                  </ListItem>
                  <ListItem>
                    <BaseTypography>Full editorial and commercial control</BaseTypography>
                  </ListItem>
                </ListRoot>
              </Grid>
              <Grid item sx={(theme) => ({
                mb: 2,
                [theme.breakpoints.up('md')]: {
                  mr: 4,
                  mb: 0
                }
              })}>
                <SubmitBtn  href={'/video-revenue-calculator/'} variant={'contained-x'} fullWidth>Calculate Revenue Uplift</SubmitBtn>
              </Grid>
              <Grid item sx={theme => ({
                [theme.breakpoints.down('md')]: {
                  display: 'none'
                }
              })}>
                <VideoProviderText>Integrates with:</VideoProviderText>
                <VideoProviderDesktopImageRoot><img src={'/video-providers.png'} alt={'JWPLAYER - dailymotion -' +
                  ' brightcove'} /></VideoProviderDesktopImageRoot>
              </Grid>
            </Grid>
          </ContentWrap>
        </GridContentItem>
        <Grid item xs={12} md={6} sx={theme => ({
          display: 'flex',
          flexDirection: 'column',
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        })}>
          <ShowcaseImage />
        </Grid>
      </GridCenterAligned>
      <GridMobileWithBackground sx={theme => ({
          [theme.breakpoints.up('md')]: {
            display: 'none'
          }
        })}>
        <ShowcaseImage />
        <VideoProviderMobileImageRoot>
          <img src={'/video-providers.png'} alt={'JWPLAYER - dailymotion -' +
                  ' brightcove'} />
        </VideoProviderMobileImageRoot>
      </GridMobileWithBackground>
      <BrandedCarousel />
    </PublicLayout>
  );
}

Home.propTypes = {
  className: PropTypes.string
};

export default styled(Home)`
  ${props => props.theme.breakpoints.up('md')} {
    .content-root {
      display: flex;
    }
    .content-inner {
      display: flex;
      flex-direction: column;
      
      > div:first-child {
        flex: 1;  
      }
    }
  }
  .content-inner {
    max-width: none;
    padding: 0;
    overflow: hidden;
    ${props => props.theme.breakpoints.down('md')} {
      margin-top: 40px;
    }
  }
`;
