import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const Menu = ({ onClick, className }) => <svg onClick={onClick} className={className} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"
     xmlnsXlink="http://www.w3.org/1999/xlink">
    <rect width="34" height="34" fill="url(#pattern0)"/>
    <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image0_66_661" transform="scale(0.0111111)"/>
        </pattern>
        <image id="image0_66_661" width="90" height="90"
               xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA40lEQVR4nO3ZPUrEUBSG4YudswbrnAVMMzmbm5wF2PiDrlJttFUCtoo3xeSCzwPZwEc4gbytAQAAAAAAALCf4/F8iFweI5e3yPr01M8bzPUaWQ83p9vr7qGnrGfjVtcLtm7WOfP5KnL5MHT1Dv2+bmfoHG5opyO2DD0vT52no7X1sE/zcv996H0M89eBX6a57jZ9DAEAAAAA/jlxtv7+D16crYsGC3E2Lza0OBtjDi3Ohjhbw4VicRYAAAAAYDtxtsTZGOBnvzibuw4tzsaYQ4uzIc7W7rdYnAUAAAAAAABoY/oCJOU9xWEvZfsAAAAASUVORK5CYII="/>
    </defs>
</svg>

Menu.propTypes = {
    className: PropTypes.string,
}

export default styled(Menu)`
    ${props => props.theme.breakpoints.up('md')} {
        display: none;
    }
`;
