import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';

import { ReactComponent as ImageArticleCount } from '../icons/image-article-count.svg';
import { ReactComponent as BrandSafeIcon } from '../icons/brand_safe.svg';
import { ReactComponent as NewIcon } from '../icons/isnew.svg';

import { ReactComponent as GainingIcon } from '../icons/gaining.svg';
import { ReactComponent as HasRankedIcon } from '../icons/has-ranked.svg';
import { ReactComponent as NotRankingIcon } from '../icons/not-ranking.svg';
import { ReactComponent as LostRankingIcon } from '../icons/lost-ranking.svg';

import { ReactComponent as PencilIcon } from '../icons/pencil.svg';
import { ReactComponent as GainingArticleIcon } from '../icons/gaining-article.svg';
import { ReactComponent as DropingArticleIcon } from '../icons/dropping-article.svg';
import { ReactComponent as ToggleIcon } from '../icons/chevron_toggle.svg';

import { Box, CircularProgress, Grid, LinearProgress, Typography } from '@mui/material';

import ToolBarKeywords from './ToolBarKeywords';
import PaginationTable from './Pagination';
import { apiKeywordProvider } from '../store/keywordSlice';
import { TableCellBody, TableCellHead, TableRow } from './common/Table';
import TableBody from '@mui/material/TableBody';
import { VideoThumbnail } from './common/VideoThumbnail';
import { MatchType } from './common/MatchType';
import { BoldTypographyLarge } from './common/TextLabel';
import { styled } from '@mui/material/styles';
import Moment from 'react-moment';
import moment from 'moment';
import { VideoImageSwapDialog } from './modal/VideoImageSwapDialog';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { useState } from 'react';
import { MergeKeywordVideoImageDialog } from './modal/MergeKeywordVideoImageSwapDialog';
import Avatar from '@mui/material/Avatar';

const TableRowPrimary = styled(TableRow)`
  background-color: #fff;
`;

const PublishDate = styled('div')`
  font-family: 'ProximaNovaBold';
  color: #212d5a;
  font-size: 14px;
`;

const ArticleTitle = styled('div')`
  color: #212d5a;
  font-size: 14px;
  font-family: 'ProximaNovaLight';
`;

const Capitalize = styled(Typography)`
  color: '#000';
  text-decoration: none;
  text-transform: capitalize;
  font-family: 'ProximaNovaBold';
  font-size: 16px;
  position: relative;
`;

const Link = styled('a')`
  text-decoration: none;
  color: inherit;
`;

const Category = styled('div')``;

const KeywordRoot = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ImageCountIcon = styled(ImageArticleCount)`
  width: 32px;
  height: 32px;
`;

const VideoArticleLabel = styled('div')`
  text-transform: uppercase;
  position: absolute;
  top: -9px;
  left: 16px;
  color: #fff;
  background-color: #3355d1;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: bold;
`;

const TableCellBodyWithLine = styled(TableCellBody)`
  &:before {
    content: ' ';
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: #3355d1;
    top: 50%;
    left: 22px;
    border-radius: 50%;
  }
  &:after {
    content: ' ';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #3355d1;
    top: 0;
    left: 25px;
  }
  &.last:after {
    height: 50%;
  }
`;

const ArticleRankingRoot = styled('div')`
  position: absolute;
  height: 15px;
  width: 15px;
  left: 18px;
  top: 50%;
  z-index: 10;
  & > svg {
    position: relative;
    margin-top: -50%;
  }
`;
const RowIconRoot = styled('span')`
  position: absolute;
  left: -40px;
  top: 4px;
  display: flex;
`;
const ToggleRow = styled('span')`
  svg {
    transition: 0.2s;
  }
  &.active svg {
    transform: rotate(90deg);
  }
  cursor: pointer;
`;

const ImageCountRoot = styled('div')`
  position: relative;
  width: 32px;
  height: 32px;

  & svg {
    position: absolute;
    left: 0;
    top: 0;
  }

  & span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: relative;
    color: white;
    font-weight: bold;
  }
`;

const Top3 = styled(Avatar)`
  width: 16px;
  height: 16px;
  background-color: #3355d1;
  font-size: 12px;
  display: inline-flex;
  font-family: ProximaNovaBold;
`;

const KeywordRows = ({
  setArticleVideoPreview,
  redirectToImageSwap,
  loadingId,
  setVideoOnKeywordPreview,
  filterArticleType,
  item: [key, keyword],
}) => {
  const [toggleRow, setToggleRow] = useState(false);
  const totalVideo = keyword.image_articles.map(item => item.videos.length).reduce((a, n) => a + n, 0);
  const articleTypeLabel = filterArticleType === 'video-with-image' ? 'Video articles' : 'Image articles';
  return (
    <>
      <TableRowPrimary sx={{ height: '60px' }} key={key}>
        <TableCellBody padding={'none'} sx={{ minWidth: '20px' }}></TableCellBody>
        <TableCellBody sx={{ width: '35%' }}>
          <KeywordRoot>
            <Capitalize>
              <RowIconRoot>
                <NewIcon style={{ visibility: keyword.is_new ? 'visible' : 'hidden' }} />
                <ToggleRow className={toggleRow ? 'active' : ''} onClick={() => setToggleRow(!toggleRow)}>
                  <ToggleIcon />
                </ToggleRow>
              </RowIconRoot>
              <Link href={keyword.search_link} target={'_blank'}>
                {keyword.keyword}
              </Link>
            </Capitalize>
            <Category>{keyword.category}</Category>
          </KeywordRoot>
        </TableCellBody>
        <TableCellBody sx={{ width: '20%' }}>
          {keyword.ranking_type === 'Ranking' && <GainingIcon />}
          {keyword.ranking_type === 'Lost ranking' && <LostRankingIcon />}
          {keyword.ranking_type === 'Not ranking' && <NotRankingIcon />}
          {keyword.ranking_type === 'Has ranked' && <HasRankedIcon />}
        </TableCellBody>
        <TableCellBody>
          <MatchType data={keyword.image_articles[0]} />
        </TableCellBody>
        <TableCellBody />
        <TableCellBody
          onClick={() => {
            setVideoOnKeywordPreview(keyword);
          }}
        >
          {totalVideo > 0 && (
            <VideoThumbnail
              imageUrl={keyword.image_articles.find(item => item.videos.length > 0).video_thumbnail}
              videoCount={keyword.image_articles.map(item => item.videos.length).reduce((a, n) => a + n, 0)}
            />
          )}
        </TableCellBody>
        <TableCellBody>
          <BoldTypographyLarge>
            {keyword.image_articles[0].producer ? `BY ${keyword.image_articles[0].producer}` : ''}
          </BoldTypographyLarge>
          <BoldTypographyLarge>
            {keyword.videos.length > 0 && keyword.videos[0].producer && `BY ${keyword.videos[0].producer}`}
          </BoldTypographyLarge>
        </TableCellBody>
        <TableCellBody>{keyword.total_traffic.toLocaleString()}</TableCellBody>
        <TableCellBody>
          <ImageCountRoot onClick={() => redirectToImageSwap(keyword.id)}>
            <ImageCountIcon />
            <span>{keyword.image_articles_count}</span>
          </ImageCountRoot>
        </TableCellBody>
      </TableRowPrimary>
      {keyword.image_articles.map((article, index, list) => (
        <TableRow
          style={{ display: toggleRow ? 'table-row' : 'none' }}
          sx={{ height: '60px', position: 'relative' }}
          key={key + article.id}
        >
          <TableCellBody padding={'none'} />
          <TableCellBodyWithLine className={index === list.length - 1 ? 'last' : ''} colSpan={2}>
            {index === 0 && <VideoArticleLabel>{articleTypeLabel}</VideoArticleLabel>}
            <ArticleRankingRoot>
              {article.ranking_type === 'Ranking' && <GainingArticleIcon />}
              {article.ranking_type === 'Lost ranking' && <DropingArticleIcon />}
            </ArticleRankingRoot>
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item>
                <PublishDate>
                  {moment(article.publish_date).isSame(moment(), 'day') && <div>Today</div>}
                  {!moment(article.publish_date).isSame(moment(), 'day') && (
                    <div>
                      <Moment format={'DD MMM Y'}>{article.publish_date}</Moment>
                    </div>
                  )}
                  <Moment format={'HH:mm'}>{article.publish_date}</Moment>
                </PublishDate>
              </Grid>
              <Grid item flex={1}>
                <ArticleTitle>
                  <Link href={article.article_url.replace('?amp', '')} target={'_blank'}>
                    {article.article_title} {article.has_ranked_in_top_three && <Top3 variant="rounded">3</Top3>}
                  </Link>
                </ArticleTitle>
              </Grid>
            </Grid>
          </TableCellBodyWithLine>
          <TableCellBody>
            <MatchType data={article} />
          </TableCellBody>
          <TableCellBody>{article.brand_safe && <BrandSafeIcon />}</TableCellBody>
          <TableCellBody onClick={() => setArticleVideoPreview(article)}>
            {article.videos.length ? (
              <VideoThumbnail imageUrl={article.videos[0].video_thumbnail} videoCount={article.videos.length} />
            ) : (
              ''
            )}
          </TableCellBody>
          <TableCellBody>
            <BoldTypographyLarge>{article.producer ? `BY ${article.producer}` : ''}</BoldTypographyLarge>
          </TableCellBody>
          <TableCellBody>{article.page_view_count.toLocaleString()}</TableCellBody>
          <TableCellBody>
            {article.id === loadingId && <CircularProgress />}
            {article.id !== loadingId && (
              <PencilIcon style={{ cursor: 'pointer' }} onClick={() => window.open(article.cms_edit_url, '_blank')} />
            )}
          </TableCellBody>
        </TableRow>
      ))}
    </>
  );
};

export default function Keywords({ publishers, setPublisher, selectedPublisher }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingId, setLoadingId] = React.useState(null);
  const [articleVideoPreview, setArticleVideoPreview] = React.useState<any>(false);
  const [videoOnKeywordPreview, setVideoOnKeywordPreview] = React.useState<any>(false);
  const keywordsFilterType = useSelector((state: any) => state.keyword.filterDateRange);

  const filterHasRanked = useSelector((state: any) => state.keyword.filterHasRanked);

  const filterArticleType = useSelector((state: any) => state.keyword.filterArticleType);

  let keywords = useSelector((state: any) => state.keyword.keywords);

  const pagination = useSelector((state: any) => state.keyword.pagination);

  const busy = useSelector((state: any) => state.keyword.busy);

  const updateList = () => {
    dispatch<any>(
      apiKeywordProvider({
        publisher: selectedPublisher,
        limit: pagination.itemsPerPage,
        page: pagination.currentPage,
        filterByDate: keywordsFilterType,
        orderBy: 'date',
        orderDirection: 'desc',
        type: filterArticleType,
        hasRanked: filterHasRanked,
      })
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(updateList, [
    selectedPublisher,
    pagination.itemsPerPage,
    pagination.currentPage,
    keywordsFilterType,
    filterArticleType,
    filterHasRanked,
  ]);

  const handleClose = () => {
    setArticleVideoPreview(null);
    setVideoOnKeywordPreview(null);
  };

  const redirectToImageSwap = id => {
    dispatch({
      type: 'SET_IMAGE_SWAP_FILTERED_TOPIC',
      payload: [id],
    });
    dispatch({
      type: 'SET_GROUP_BY_KEYWORD',
      payload: true,
    });
    navigate('/dashboard/video-swap');
  };

  const createPost = (articleId, videoId) => {
    setLoadingId(articleId);
    axios
      .post(
        `${getApiBaseUrl()}/${selectedPublisher}/article-match/${articleId}/send/?video_id=${videoId}`,
        {},
      )
      .then(
        response => {
          if (response.status === 201) {
            dispatch({ type: 'MATCH_UPDATE', payload: articleId });
            handleClose();
            updateList();
            setLoadingId(null);
          }
        },
        () => {
          setLoadingId(null);
          alert('Unable to add this video');
          handleClose();
        }
      );
  };

  return (
    <React.Fragment>
      {articleVideoPreview && (
        <VideoImageSwapDialog
          open={Boolean(articleVideoPreview)}
          handleClose={handleClose}
          article={articleVideoPreview}
          onRemoveVideo={null}
          dialogText={articleVideoPreview?.keyword}
          onAddVideo={({ video_id: vid }) => {
            createPost(articleVideoPreview?.id, vid);
          }}
        />
      )}

      {videoOnKeywordPreview && (
        <MergeKeywordVideoImageDialog
          open={Boolean(videoOnKeywordPreview)}
          handleClose={handleClose}
          videos={videoOnKeywordPreview.image_articles
            .map(({ id, thumbnail, article_title, article_url, videos }) =>
              videos.map(video => ({ thumbnail, id, article_url, article_title, ...video }))
            )
            .flat()}
          onRemoveVideo={null}
          onAddVideo={({ video_id: vid, article_id: aid }) => {
            createPost(aid, vid);
          }}
        />
      )}

      <ToolBarKeywords publishers={publishers} setPublisher={setPublisher} selectedPublisher={selectedPublisher} />

      {busy && keywords && <LinearProgress color="success" />}

      <Table size="small">
        <TableHead>
          <TableRowPrimary>
            <TableCellHead padding={'none'} />
            <TableCellHead>TOPIC</TableCellHead>
            <TableCellHead>RANKING</TableCellHead>
            <TableCellHead>MATCH</TableCellHead>
            <TableCellHead></TableCellHead>
            <TableCellHead>VIDEO</TableCellHead>
            <TableCellHead>PRODUCER</TableCellHead>
            <TableCellHead>TRAFFIC</TableCellHead>
            <TableCellHead>TOOLS</TableCellHead>
          </TableRowPrimary>
        </TableHead>
        <TableBody>
          {!keywords && busy && (
            <TableRowPrimary>
              <TableCellHead colSpan={9} align={'center'}>
                <Box style={{ padding: 20 }}>
                  <CircularProgress />
                </Box>
              </TableCellHead>
            </TableRowPrimary>
          )}
          {keywords &&
            Object.entries<Record<string, any>>(keywords).map(item => (
              <KeywordRows
                key={item[0]}
                loadingId={loadingId}
                setArticleVideoPreview={setArticleVideoPreview}
                setVideoOnKeywordPreview={setVideoOnKeywordPreview}
                redirectToImageSwap={redirectToImageSwap}
                item={item}
                filterArticleType={filterArticleType}
              />
            ))}
        </TableBody>
      </Table>
      {busy && keywords && <LinearProgress color="success" />}
      <PaginationTable
        busy={busy}
        defaultSize={50}
        startPage={pagination.currentPage - 1}
        count={pagination.totalItems}
        onChange={page =>
          dispatch({
            type: 'SET_PAGINATION',
            payload: {
              currentPage: page + 1,
            },
          })
        }
        updateRowPerPage={totalPerPage => {
          dispatch({
            type: 'SET_PAGINATION',
            payload: {
              currentPage: 1,
              itemsPerPage: totalPerPage,
            },
          });
        }}
      />
    </React.Fragment>
  );
}
