import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid } from '@mui/material';

import { ReactComponent as Filter } from '../icons/filter.svg';
import { ReactComponent as VideoWhite } from '../icons/video_white.svg';
import { ReactComponent as VideoBlue } from '../icons/video_blue.svg';
import ImageIcon from '@mui/icons-material/Image';

import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';

import { ReactComponent as CalendarIcon } from '../icons/calendar-blue.svg';
import { ButtonGroup, MenuItem, ListItemIcon, ListItemText, Select, Typography } from '@mui/material';

import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

import { PublicationSelector } from './PublicationSelector';
import { ToolbarHeading } from './common/Heading';
import { useEffect } from 'react';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#3355D1',
    },
  },
});

const ListItemWithIcon = styled(ListItemIcon)`
  display: flex;
  align-items: center;
`;

export default function ToolbarKeywords({ publishers, setPublisher, selectedPublisher }) {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = React.useState('7days');
  const [selectedArticleType, setSelectedArticleType] = React.useState([1]);
  const [recentlyRankedToggle, setRecentlyRankedToggle] = React.useState(true);

  useEffect(() => {
    let articleType = 'image-only';

    if ([1, 2].every(x => selectedArticleType.includes(x))) {
      articleType = 'all';
    } else if (selectedArticleType.length === 0) {
      articleType = 'none';
    } else if (selectedArticleType.includes(2)) {
      articleType = 'video-with-image';
    }

    dispatch({
      type: 'SET_FILTER_ARTICLES',
      payload: {
        filterHasRanked: recentlyRankedToggle,
        filterArticleType: articleType,
        filterDateRange: dateRange,
      },
    });
  }, [dateRange, selectedArticleType, recentlyRankedToggle, dispatch]);

  return (
    <Toolbar style={{ paddingBottom: '15px', paddingLeft: '15px' }}>
      <Grid item xs={10}>
        <Grid container alignItems={'center'}>
          <ToolbarHeading>Keywords</ToolbarHeading>
          <ButtonGroup sx={{ marginRight: '20px' }}>
            <FormControl
              variant="outlined"
              margin="dense"
              style={{
                marginTop: '0px',
                marginBottom: '0px',
              }}
            >
              <Select
                id="demo-simple-select"
                defaultValue={dateRange}
                onChange={e => setDateRange(e.target.value)}
                sx={{
                  fontFamily: 'ProximaNovaBold',
                  textTransform: 'none',
                  color: '#000',
                  height: '43px',
                  width: '200px',
                  marginRight: '30px',
                }}
              >
                <MenuItem value="Dates">
                  <ListItemWithIcon>
                    <CalendarIcon />
                    <ListItemText
                      primary="Dates"
                      style={{
                        paddingLeft: '10px',
                      }}
                    />
                  </ListItemWithIcon>
                </MenuItem>
                <MenuItem value={'1day'}>last 24 hours</MenuItem>
                <MenuItem value={'yesterday'}>yesterday</MenuItem>
                <MenuItem value={'7days'}>7 day trend</MenuItem>
                <MenuItem value={'30days'}>30 day trend</MenuItem>
              </Select>
            </FormControl>
          </ButtonGroup>
          <ThemeProvider theme={theme}>
            <ButtonGroup
              variant="contained"
              disableElevation
              color="primary"
              sx={{ marginRight: '50px', height: '43px' }}
            >
              <Button
                sx={{
                  textTransform: 'none',
                  border: '1px #e0e0e0 solid',
                }}
                color={selectedArticleType.includes(1) ? 'secondary' : 'primary'}
                onClick={() => {
                  const selected = 1;
                  if (selectedArticleType.includes(selected)) {
                    setSelectedArticleType(selectedArticleType.filter(x => x !== selected));
                  } else {
                    setSelectedArticleType([...selectedArticleType, selected]);
                  }
                }}
                startIcon={<ImageIcon color={selectedArticleType.includes(1) ? 'primary' : 'secondary'} />}
              >
                <Typography variant="body1" style={{ fontFamily: 'ProximaNovaBold' }}>
                  Image
                </Typography>
              </Button>
              <Button
                sx={{
                  textTransform: 'none',
                  border: '1px #e0e0e0 solid',
                }}
                color={selectedArticleType.includes(2) ? 'secondary' : 'primary'}
                onClick={() => {
                  const selected = 2;
                  if (selectedArticleType.includes(selected)) {
                    setSelectedArticleType(selectedArticleType.filter(x => x !== selected));
                  } else {
                    setSelectedArticleType([...selectedArticleType, selected]);
                  }
                }}
                startIcon={selectedArticleType.includes(2) ? <VideoWhite /> : <VideoBlue />}
              >
                <Typography variant="body1" style={{ fontFamily: 'ProximaNovaBold' }}>
                  Video
                </Typography>
              </Button>
            </ButtonGroup>
          </ThemeProvider>
          <ButtonGroup sx={{ marginRight: '20px' }}>
            <PopupState variant="popover">
              {popupState => (
                <React.Fragment>
                  <ThemeProvider theme={theme}>
                    <Button disableElevation variant="contained" {...bindTrigger(popupState)}>
                      <ListItemIcon>
                        <Filter
                          style={{
                            marginTop: '7px',
                          }}
                        />
                        <ListItemText
                          primary="Filter"
                          style={{
                            paddingLeft: '10px',
                          }}
                        />
                      </ListItemIcon>
                    </Button>
                  </ThemeProvider>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={recentlyRankedToggle}
                              onChange={() => setRecentlyRankedToggle(!recentlyRankedToggle)}
                            />
                          }
                          label="Recently ranked"
                        />
                      </FormGroup>
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <FormControl variant="outlined" margin="dense">
          <PublicationSelector
            publishers={publishers}
            onChange={setPublisher}
            selectedPublication={selectedPublisher}
          />
        </FormControl>
      </Grid>
    </Toolbar>
  );
}
