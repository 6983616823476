import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography, useTheme
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import * as React from 'react';
import ReactJWPlayer from 'react-jw-player';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import { ReactComponent as BrandSafeIcon } from '../../icons/brand_safe.svg';
import { BoldTypographyLarge } from '../common/TextLabel';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { grey } from '@mui/material/colors';
import { MatchType } from '../common/MatchType';
import { Link } from '@mui/material';

const CloseBtnRoot = styled('div')`
  position: absolute;
  right: 10px;
  top: 10px
`;

const Root = styled('div')`
  padding: 20px 40px 0; 
  position: relative;
`;

const Left = styled(Avatar)`
  position: absolute;
  left:10px;
  top: 50%;
`;

const Right = styled(Avatar)`
  position: absolute;
  right: 10px;
  top: 50%;
`;

const ReactJWPlayerRoot = styled(ReactJWPlayer)`
  min-height: 265px;
`;

const TitleDialog = styled(DialogTitle)`
  position: relative;
  padding-top: 0;
  &:after {
    content: ' ';
    background-color: #ccc;
    width: calc(100% + 80px);
    height: 1px;
    position: absolute;
    left: -40px;
    bottom: 10px;
  }
`;

const Title = styled('h3')`
  text-transform: capitalize;
  margin: 24px 0 0px 0;
`

const BrandSafeRoot = styled(BrandSafeIcon)`
  margin: 0 16px;
`;

export const MergeKeywordVideoImageDialog = ({
  open,
  handleClose,
  videos,
  onAddVideo,
  onRemoveVideo,
  dialogText = false,
  ctaText = 'Add video'
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  useEffect(() => {
    setActiveIndex(0);
  }, []);
  const { palette: { primary: { main: primaryColor }}} = useTheme();
  return (<Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <Root>
        <CloseBtnRoot>
          <IconButton onClick={handleClose}>
            <CloseIcon/>
          </IconButton>
        </CloseBtnRoot>
        { !dialogText && <TitleDialog>
          <Grid container alignItems={'center'}>
            <Grid item xs={3}>
              <img src={`${videos[activeIndex].thumbnail}?width=100`} alt={''} style={{ maxWidth: '100px' }} />
            </Grid>
            <Grid item xs={9}>
              <Typography><Link href={videos[activeIndex].article_url} target={'_blank'}>{videos[activeIndex].article_title}</Link></Typography>
            </Grid>
          </Grid>
          </TitleDialog> }
        { dialogText && <TitleDialog><Title>{dialogText}</Title></TitleDialog> }
        <DialogContent>
          {videos?.map((video, i) => (
            <Grid key={i} sx={{ display: activeIndex === i ? 'flex' : 'none' }} container spacing={1}>
              <Grid item xs={10}>
                <Grid container alignItems={'center'}>
                  <BoldTypographyLarge>BY {video.producer}</BoldTypographyLarge>
                  <BrandSafeRoot title={'Brand safe'}/>
                  <MatchType data={video}/>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Typography textAlign={'right'}>
                  {activeIndex + 1} / {videos?.length}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ReactJWPlayerRoot
                  playerId={video.video_url}
                  playerScript="https://content.jwplatform.com/libraries/nmOQniK0.js"
                  playlist={video.video_url}
                />
              </Grid>
              <Grid item xs={9}>{video.video_title}</Grid>
              <Grid item xs={3}><Moment
                format="Do MMM YYYY">{video.video_published_date}</Moment></Grid>
            </Grid>
          ))}
          <Grid sx={{ marginTop: 3 }} container justifyContent={'space-between'} flexDirection={'row-reverse'}>
            <Button variant="contained" onClick={() => onAddVideo(videos[activeIndex])}>{ctaText}</Button>
            { onRemoveVideo && <Button variant="contained" color={'warning'} onClick={() => { onRemoveVideo(videos[activeIndex].video_id)}}>Remove</Button> }
          </Grid>
          {videos?.length > 0 &&
            <Left
              sx={{ bgcolor: activeIndex === 0 ? grey[400] : primaryColor }}
              onClick={() => {
                if (activeIndex !== 0) {
                  setActiveIndex(activeIndex - 1);
                }
              }}><ChevronLeft/></Left>}
          {videos?.length > 0 &&
            <Right
              sx={{ bgcolor: activeIndex === videos?.length - 1 ? grey[400] : primaryColor }}
              onClick={() => {
                if (activeIndex !== videos?.length - 1) {
                  setActiveIndex(activeIndex + 1);
                }
              }}><ChevronRight/></Right>}
        </DialogContent>
      </Root>
    </Dialog>
  );
};