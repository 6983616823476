import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';

const fetchArticlesSuccess = payload => ({
  type: 'FETCH_ARTICLES_SUCCESS',
  payload,
});

const fetchGroupByKeywordSuccess = payload => ({
  type: 'FETCH_GROUP_BY_KEYWORD_SUCCESS',
  payload,
});

const updateBusyIndicator = payload => ({
  type: 'FETCH_GROUP_BY_KEYWORD_BUSY',
  payload,
});

export const apiArticleProvider =
  ({ publisher, isGroupByKeyword, ...params }) =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      const { data } = await axios.get(
        `${getApiBaseUrl()}/${publisher}/article-match/${isGroupByKeyword ? 'keywords' : ''}`,
        {
          params,
        }
      );
      if (!isGroupByKeyword) {
        dispatch(fetchArticlesSuccess(data));
      } else {
        dispatch(fetchGroupByKeywordSuccess(data));
      }
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export interface EngageState {
  articles: any;
  articleGroupByKeyword: any;
  articlesFiltered: any;
  articlesFilter: string;
  paginationPage: number;
  isGroupByKeyword: boolean;
  busy: boolean;
  paginationPerPage: number;
  filteredTopic: number[];
  pagination: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

const initialState: EngageState = {
  articles: [],
  articleGroupByKeyword: {},
  articlesFiltered: [],
  filteredTopic: [],
  articlesFilter: '7days',
  paginationPage: 0,
  paginationPerPage: 20,
  isGroupByKeyword: false,
  busy: false,
  pagination: {
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 20,
    totalItems: 0,
    totalPages: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IMAGE_SWAP_FILTERED_TOPIC':
      return {
        ...state,
        filteredTopic: action.payload,
      };
    case 'SET_PAGINATION': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    }
    case 'FETCH_GROUP_BY_KEYWORD_SUCCESS': {
      let { items: articleGroupByKeyword, meta: pagination } = action.payload;
      return {
        ...state,
        articles: [],
        articleGroupByKeyword,
        pagination,
      };
    }
    case 'FETCH_GROUP_BY_KEYWORD_BUSY':
      return {
        ...state,
        busy: action.payload,
      };
    case 'SET_GROUP_BY_KEYWORD':
      return {
        ...state,
        isGroupByKeyword: action.payload,
      };
    case 'MATCH_UPDATE':
      return {
        ...state,
        articles: state.articles.map(a => (a.id === action.payload ? { ...a, has_been_swapped: 'true' } : a)),
        articlesFiltered: state.articlesFiltered.map(a =>
          a.id === action.payload ? { ...a, has_been_swapped: 'true' } : a
        ),
      };
    case 'FETCH_ARTICLES_SUCCESS':
      let { items: articles, meta: pagination } = action.payload;
      return {
        ...state,
        articles,
        articleGroupByKeyword: initialState.articleGroupByKeyword,
        pagination,
      };
    case 'SET_FILTER_ARTICLES':
      return {
        ...state,
        articlesFilter: action.payload,
        pagination: {
          ...state.pagination,
          currentPage: 1,
        },
      };
    default:
      return state;
  }
};

export default reducer;
