import styled from '@emotion/styled';
import { Button, FormControl, Grid, TextField } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { getApiBaseUrl } from '../utils/env';
import { Heading } from './common/Heading';

const Root = styled('div')`
  position: relative;
  .linear-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  .linear-top {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
`;

export default function GenerateArticle() {
  const isAdmin = localStorage.getItem('is_admin') === 'true';
  if (!isAdmin) {
    window.location.href = '/';
  }

  const [data, setData] = useState({
    bias: '',
    numwords: '',
    description: '',
  });
  const [output, setOutput] = useState({
    prompt: '',
    text: '',
  });
  const changeData =
    key =>
    ({ target: { value } }) =>
      setData({
        ...data,
        [key]: value,
      });

  const handleSubmit = e => {
    e.preventDefault();
    const payload: any = data;
    axios['post'](`${getApiBaseUrl()}/independent/smart-library/generate-article-text`, payload)
      .then(({ data }) => setOutput(data))
      .catch(ex => {
        const { message } = ex.response?.data;
        if (Array.isArray(message) && message.length > 0) {
          alert(message.join('\n'));
        }
      });
  };

  return (
    <React.Fragment>
      <Root>
        <Grid container style={{ paddingBottom: '15px', paddingLeft: '15px' }}>
          <Grid container alignItems="center" justifyContent={'space-between'}>
            <Grid item>
              <Heading>{'Generate Article'}</Heading>
            </Grid>
          </Grid>
        </Grid>
      </Root>
      <Root>
        <form onSubmit={handleSubmit} style={{ width: 600 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  name="bias"
                  value={data.bias}
                  label="Bias"
                  variant="outlined"
                  placeholder={'eg. left, center, or right'}
                  onChange={changeData('bias')}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type={'number'}
                  fullWidth
                  value={data.numwords}
                  name="numwords"
                  label="Number of words"
                  variant="outlined"
                  placeholder={'eg. 150'}
                  onChange={changeData('numwords')}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type={'description'}
                  fullWidth
                  value={data.description}
                  name="description"
                  label="Description"
                  variant="outlined"
                  placeholder={'eg. My description here'}
                  onChange={changeData('description')}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button color="primary" variant="contained" type={'submit'}>
                    Generate
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        {/*<h2>Prompt used:</h2>*/}
        {/*<p>{output.prompt}</p>*/}
        <h2>Article text:</h2>
        <p>{output.text}</p>
      </Root>
    </React.Fragment>
  );
}
