import TableHead from '@mui/material/TableHead';
import { TableCellBody, TableCellHead, TableRow } from './common/Table';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import { BoldTypography, BoldTypographyLarge } from './common/TextLabel';
import Moment from 'react-moment';
import { CircularProgress } from '@mui/material';
import { MatchType } from './common/MatchType';
import { VideoThumbnail } from './common/VideoThumbnail';
import { ReactComponent as BrandSafeIcon } from '../icons/brand_safe.svg';
import plusButton from '../icons/plus.png';
import Table from '@mui/material/Table';
import * as React from 'react';
import { Link } from './common/Link';
import { urlAppendQs } from '../utils/common';
import { demoPlayerId } from '../constant/player';
import { decode } from 'he';

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const ImageSwapArticleTable = ({
  onSortByTraffic,
  onSortByDate,
  articles,
  onSortByScore,
  orderBy,
  orderDirection,
  onSetArticleForPreview,
  loadingId,
  articleDemo
}) => {
  return (<Table size="small">
        <TableHead>
          <TableRow>
            <TableCellHead
              style={{ cursor: 'pointer' }}
              onClick={onSortByTraffic}
            ><TableSortLabel
              active={orderBy === 'traffic'}
              direction={orderDirection || 'asc'}
            >TRAFFIC</TableSortLabel></TableCellHead>
            <TableCellHead>
              THUMBNAIL
            </TableCellHead>
            <TableCellHead
              style={{ cursor: 'pointer' }}
              onClick={onSortByDate}
            ><TableSortLabel
              active={orderBy === 'date'}
              direction={orderDirection || 'asc'}
            >
              DATE</TableSortLabel>
            </TableCellHead>
            <TableCellHead>
              ARTICLE TITLE
            </TableCellHead>
            <TableCellHead>
              SECTION
            </TableCellHead>
            <TableCellHead
              style={{ cursor: 'pointer' }}
              onClick={onSortByScore}
            ><TableSortLabel
              active={orderBy === 'score'}
              direction={orderDirection || 'asc'}
            >
              MATCH</TableSortLabel>
            </TableCellHead>
            <TableCellHead>
              VIDEO
            </TableCellHead>
            { !articleDemo && (<TableCellHead>
            </TableCellHead>) }
            <TableCellHead width="20%">
              PRODUCER
            </TableCellHead>
            <TableCellHead width="160">
              TOOLS
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {articles.length === 0 && <TableRow>
            <TableCellBody colSpan={10} width={'100%'}>
              <div style={{ textAlign: 'center', padding: 20 }}>No matching article found.</div>
            </TableCellBody>
          </TableRow>}
          {articles &&
            articles.map((row) => (
              <TableRow
                sx={{
                  height: '60px',
                  opacity: row.has_been_swapped ? '.2' : '1',
                }}
                key={row.id}
              >
                <TableCellBody>{row.page_view_count.toLocaleString()}</TableCellBody>
                <TableCellBody sx={{ minWidth: '90px' }}>
                  <div
                    style={{
                      backgroundImage: `url(${row.thumbnail}?width=100)`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: '57px',
                      borderRadius: '5%',
                    }}
                  ></div>
                </TableCellBody>
                <TableCellBody>
                  <BoldTypography>
                    <Moment format="Do MMMM">{row.publish_date}</Moment>
                  </BoldTypography>
                </TableCellBody>
                <TableCellBody sx={{ minWidth: '287px' }}>
                  <Link
                    className="hover-underline"
                    href={row.article_url}
                    target="_blank"
                    variant="body1"
                    style={{
                      color: 'black',
                      fontFamily: 'ProximaNovaLight',
                      fontSize: 14,
                      textDecoration: 'none',
                    }}
                  >
                    {decode(row.article_title)}
                  </Link>
                </TableCellBody>
                <TableCellBody sx={{ minWidth: '117px' }}>
                  <BoldTypographyLarge>
                    {capitalize(row.section || '')}
                  </BoldTypographyLarge>
                </TableCellBody>
                <TableCellBody sx={{ minWidth: '120px' }}>
                  <MatchType data={row}/>
                </TableCellBody>
                <TableCellBody
                  sx={{ minWidth: '90px' }}
                  onClick={() => {
                    if (row.videos.length) {
                      onSetArticleForPreview(row);
                    }
                  }}
                >
                  {row.videos.length > 0 &&
                    <VideoThumbnail imageUrl={row.video_thumbnail} videoCount={row.videos.length}/>}
                </TableCellBody>
                { !articleDemo && (<TableCellBody>
                  <BrandSafeIcon/>
                </TableCellBody>) }
                <TableCellBody sx={{ minWidth: '150px' }}>
                  <BoldTypographyLarge>
                    {row.producer && `BY ${row.producer}`}
                  </BoldTypographyLarge>
                </TableCellBody>
                <TableCellBody sx={{ minWidth: '200px' }}>
                  {row.id === loadingId && <CircularProgress/>}
                  {(!row.has_been_swapped && row.id && row.videos.length > 0 && !loadingId && !articleDemo) && (
                    <span
                      onClick={() => onSetArticleForPreview(row)}
                      style={{ cursor: 'pointer' }}
                    >
                      <img width="28" src={plusButton} alt="plus button"/>
                    </span>
                  )}
                  { articleDemo && row.videos.length > 0 && (
                     <Link
                       href={urlAppendQs(row.article_url, {
                         pixels_inject_media_id: row.videos[0].mediaid,
                         pixels_inject_player_id: demoPlayerId,
                         pixels_inject_tag: row.imageSelector
                       })}
                       target={'_blank'}
                      style={{ cursor: 'pointer' }}
                    >
                      <img width="28" src={plusButton} alt="plus button"/>
                    </Link>
                  )}
                  {/* Enable when there is action for this.. */}
                  {/*&nbsp;&nbsp;&nbsp;&nbsp;*/}
                  {/*<SvgIcon>*/}
                  {/*  <PencilIcon/>*/}
                  {/*</SvgIcon>*/}
                </TableCellBody>
              </TableRow>
            ))}
        </TableBody>
      </Table>)
}