import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

// export const Headline = styled(Typography)`
//   font-size: 34px;
//   line-height: 1;
//   margin-bottom: 16px;
//   ${props => props.theme.breakpoints.up('md')} {
//     line-height: 1.525;
//     margin-bottom: 0;
//     font-size: 40px;
//   }
// `

export const Paragraph = styled(Typography)`
  font-size: 18px;
  line-height: 1.2;
  ${props => props.theme.breakpoints.up('md')} {
    line-height: 1.636;
    font-size: 22px;
  }
`;

export const BlueGradientText = styled(Typography)`
  background: linear-gradient(0deg, #3355D1, #3355D1 40%, #6391ea);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-family: ProximaNovaSemiBold;
`;


export const BaseTypography = styled(Typography)`
  font-size: 18px;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 22px;
  }
`;

export const Headline = styled(BaseTypography)`
  font-size: 34px;
  margin: 16px 0;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 40px;
  }
`;