import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { styled } from '@mui/material/styles';

import { Box, Button, FormControl, GlobalStyles, Grid, TextField, Typography } from '@mui/material';
import '../../App.css';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import axios from 'axios';
import { getApiBaseUrl } from '../../utils/env';
import PublicLayout from './PublicLayout';
import PropTypes from 'prop-types';
import { ContentWrap, GridCenterAligned, GridContentItem } from '../common/GridLayout';
import BrandedCarousel from '../BrandCarousel';
import { Headline } from '../common/PublicTypography';
import { InputLabel } from '../common/Form';
import { GridMobileWithBackground, shadeBgStyles } from './Home';

const InputText = (props) => (<TextField
  inputProps={{
   style: {
     paddingLeft: 18,
     paddingRight: 18,
     paddingTop: 12,
     paddingBottom: 12,
   }
  }}
  {...props}
 />)

const LoginInputField = styled(InputText)`
  background: #fff;
  border-radius: 6px;
`;

const LoginShowcaseImage = styled('div')`
  width: 100%;
  aspect-ratio: 1 / .75;
  position: relative;
  overflow: hidden;
  ${props => props.theme.breakpoints.up('md')} {
    overflow: visible; 
    flex: 1;
  }
  
  &:after {
    content: '';
    background-image: url(/home1-mobile.png);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    background-size: 126%;
    background-position: -50px -42px;
    background-repeat: no-repeat;
    z-index: 0;

    ${props => props.theme.breakpoints.up('md')} {
      background-image: url(/home1.png);
      top: 20px;
      height: calc(100% + 20px);
      background-size: 90%;
      width: 200%;
       background-position: -97px -46px;
      background-repeat: no-repeat;
    }
  }
`;

const Form = styled('form')`
  ${props => props.theme.breakpoints.up('md')} {
    margin-right: 40px;
  }
`

const Link = styled('a')`
  color: #fff;
  &:visited, &:active, &:hover {
    color: #fff;
  }
`;


const ErrorLogin = styled(Typography)`
  position: absolute;
  top: 10px;
  left: 0;
  background-color: #BF3F3F;
  padding: 2px 6px;
  border-radius: 4px;
  line-height: 1.4;
  font-size: 13px;
  color: #fff
`
const LoginTxtHelp = styled(Typography)`
  margin-top: 20px;
  font-size: 12px;
`;

const FormCtaRoot = styled(Box)`
  position: relative;
  padding-top: 30px;
  
`

function Login({ className }) {
  const [userData, setUserData] = useState({ email: '', password: '' });
  const [errorMessage, setErrorMessage] = useState({ value: '' });

  const loginRequest = payload => {
    return axios.post(`${getApiBaseUrl()}/auth/login`, payload).then(response => response.data);
  };

  const handleInputChange = e => {
    setUserData(prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await loginRequest({
      ...userData,
      username: userData.email,
    }).then(
      result => {
        const { access_token: token, is_admin, organisations } = result;
        localStorage.setItem('is_admin', is_admin);
        localStorage.setItem('token', token);
        localStorage.setItem('pubAccess', JSON.stringify(organisations));

        const { is_demo } = organisations[0];
        if (is_demo) {
          window.location.href = '/video-swap';
        } else {
          window.location.href = '/dashboard/video-swap';
        }
      },
      (error) => {
        localStorage.setItem('is_admin', '');
        localStorage.setItem('token', '');
        localStorage.setItem('pubAccess', '');
        setErrorMessage(prevState => ({ value: typeof error === 'string' ? error : 'Invalid' +
            ' credentials' }));
      }
    );
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const isAdmin = localStorage.getItem('is_admin');
      if (isAdmin === 'true') {
        window.location.href = '/dashboard/video-swap';
      } else {
        window.location.href = '/video-swap';
      }
    }
  }, []);

  return (
    <PublicLayout className={className}>
      <GlobalStyles styles={shadeBgStyles}/>
      <Helmet title="Login">
        <meta name="description" content="Pixels.AI - Login"/>
      </Helmet>
      <GridCenterAligned container>
        <GridContentItem item xs={12} md={6}>
          <ContentWrap className={'ctn-root'}>
            <Headline variant={'h1'}>Login</Headline>
            <Form onSubmit={handleSubmit}>
              <Grid container rowSpacing={2} spacing={3}>
                <Grid item md={6} xs={12}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel htmlFor="name">Email</InputLabel>
                    <LoginInputField
                      id={'email'}
                      name={'email'}
                      required
                      onChange={e => handleInputChange(e)}
                      onFocus={e => handleInputChange(e)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          return handleSubmit(e);
                        }
                      }}
                      placeholder={'Email'}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel htmlFor="name">Password</InputLabel>
                    <LoginInputField
                      id={'password'}
                      name={'password'}
                      type={'password'}
                      required
                      onChange={e => handleInputChange(e)}
                      onFocus={e => handleInputChange(e)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          return handleSubmit(e);
                        }
                      }}
                      placeholder={'Password'}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormCtaRoot>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {errorMessage.value && (<ErrorLogin>{errorMessage.value} </ErrorLogin>)}
                  <Grid item xs={12}>
                    <Button
                      type={'submit'}
                      style={{
                        fontSize: 17,
                        fontWeight: 700,
                        minHeight: '50px',
                        backgroundColor: '#3355D1',
                        textTransform: 'none',
                      }}
                      fullWidth
                      variant="contained"
                      endIcon={<ArrowForwardIcon/>}
                    >
                      Login to your account
                    </Button>
                  </Grid>
                </Grid>
                <LoginTxtHelp>
                  <strong><Link href={'/contact/'}>Contact us</Link> to create an account</strong>. By signing up you agree to our <em>Terms of Use</em> and <em>Privacy Policy</em>
                </LoginTxtHelp>
              </FormCtaRoot>
            </Form>
          </ContentWrap>
        </GridContentItem>
        <Grid item xs={12} md={6} sx={theme => ({
          display: 'flex',
          flexDirection: 'column',
          height: '630px',
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        })}>
          <LoginShowcaseImage />
        </Grid>
      </GridCenterAligned>
      <GridMobileWithBackground sx={theme => ({
          [theme.breakpoints.up('md')]: {
            display: 'none'
          }
        })}>
        <LoginShowcaseImage />
      </GridMobileWithBackground>
      <BrandedCarousel/>
    </PublicLayout>
  );
}

Login.propTypes = {
  className: PropTypes.string
};

export default styled(Login)`

  .content-root {
    display: flex;
  }

  .content-inner {
    max-width: none;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    
    > div:first-child {
      flex: 1;
    }

    ${props => props.theme.breakpoints.down('md')} {
      margin-top: 40px;
    }
  }
  
  ${props => props.theme.breakpoints.up('md')} {
    .ctn-root {
      padding-top: 90px;
    }
  }
`;
