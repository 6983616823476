import * as React from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Helmet from 'react-helmet';
import Keywords from './Keywords';
import logo from '../pixels-ai-logo-white.svg';
import ImageSwap from './ImageSwap';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';

import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as AcquireIcon } from '../icons/ico-keywords.svg';
import { ReactComponent as EngageIcon } from '../icons/ico-image_swap.svg';
import { ReactComponent as SecondPlayIcon } from '../icons/second_play.svg';
import { ReactComponent as PerformanceIcon } from '../icons/performance.svg';
import { ReactComponent as PIcon } from '../icons/short_logo_white.svg';
import { ReactComponent as AccountIcon } from '../icons/account.svg';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { useNavigate } from 'react-router-dom';
import PromotedContent from './PromotedContent';
import SmartLibrary from './SmartLibrary';
import { localStorageToJSON } from '../utils/common';
import { useEffect, useState } from 'react';
import { DrawerListItemText, SidebarLink } from './common/TextLabel';
import PublishersAdd from './PublishersAdd';
import PublishersDemoArticles from './PublishersDemoArticles';
import UserAdd from './UserAdd';
import ImageSwapDemo from './ImageSwapDemo';
import Calculator from './Calculator';
import Publishers from './Publishers';
import { ListItemButton } from '@mui/material';
import Users from './Users';
import UserEdit from './UserEdit';
import GenerateArticle from './GenerateArticle';

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    fontFamily: 'ProximaNovaSemiBold',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface DashboardProps {
  section: string;
  demoPage?: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ section, demoPage }: DashboardProps) => {
  const [publishers, setPublishers] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState(publishers[0]);
  const [isDemo, setIsDemo] = useState(false);

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  let navigate = useNavigate();

  const isAdmin = localStorage.getItem('is_admin') === 'true';
  const logOut = () => {
    if (window.localStorage) {
      localStorage.setItem('token', '');
      localStorage.setItem('pubAccess', '');
    }
    navigate('/', { replace: true });
  };

  useEffect(() => {
    const organisations = localStorageToJSON('pubAccess');
    const slugs = organisations.map(({ domain }) => domain);
    setPublishers(organisations);
    setSelectedPublisher(slugs[0]);
  }, []);

  useEffect(() => {
    if (!selectedPublisher) return;
    const isDemo = publishers.some(({ domain, is_demo }) => domain === selectedPublisher && Boolean(is_demo));
    setIsDemo(isDemo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublisher]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet title={'Dashboard'}></Helmet>
      <CssBaseline />
      {!open && (
        <ChevronRightIcon
          className={open ? 'show' : 'hide'}
          style={{
            color: 'white',
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 1300,
            backgroundColor: '#3355D1',
            borderRadius: '50%',
            border: '1px solid rgb(255, 255, 255, 0.4)',
            top: 18,
            boxSizing: 'content-box',
            left: 59,
            padding: 2,
            transition: 'opacity 0.3s',
            opacity: 1,
          }}
          onClick={toggleDrawer}
        />
      )}

      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: '#3355D1',
            color: 'white',
          },
        }}
        variant="permanent"
        open={open}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: [1],
          }}
        >
          <header
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Link
              style={{
                paddingLeft: 16,
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
              }}
              href="/"
            >
              {open && <img onClick={toggleDrawer} src={logo} alt="Pixels" />}
              {!open && <PIcon />}
            </Link>
          </header>
          {open && (
            <ChevronLeftIcon
              style={{
                color: 'white',
                cursor: 'pointer',
                position: 'absolute',
                right: 10,
              }}
              onClick={toggleDrawer}
            />
          )}
        </Toolbar>
        <Divider />
        <List>
          {isDemo && demoPage && (
            <>
              <SidebarLink to="/video-swap" className={navData => (navData.isActive ? 'active' : 'inactive')}>
                <ListItem button>
                  <SvgIcon style={{ marginRight: '13px', marginLeft: '7px' }}>
                    <EngageIcon />
                  </SvgIcon>
                  {open && 'Video Swap'}
                </ListItem>
              </SidebarLink>
              <SidebarLink to="/calculator" className={navData => (navData.isActive ? 'active' : 'inactive')}>
                <ListItem button>
                  <SvgIcon style={{ marginRight: '13px', marginLeft: '7px' }}>
                    <EngageIcon />
                  </SvgIcon>
                  {open && 'Calculator'}
                </ListItem>
              </SidebarLink>
            </>
          )}
          {!isDemo && !demoPage && (
            <>
              <SidebarLink
                to="/dashboard/smart-library"
                className={navData => (navData.isActive ? 'active' : 'inactive')}
              >
                <ListItem button>
                  <SvgIcon style={{ marginRight: '13px', marginLeft: '7px' }}>
                    <AcquireIcon />
                  </SvgIcon>
                  {open && 'Smart Library'}
                </ListItem>
              </SidebarLink>

              <SidebarLink to="/dashboard/video-swap" className={navData => (navData.isActive ? 'active' : 'inactive')}>
                <ListItem button>
                  <SvgIcon style={{ marginRight: '13px', marginLeft: '7px' }}>
                    <EngageIcon />
                  </SvgIcon>
                  {open && 'Video Swap'}
                </ListItem>
              </SidebarLink>

              <SidebarLink
                to="/dashboard/promoted-content"
                className={navData => (navData.isActive ? 'active' : 'inactive')}
              >
                <ListItem button>
                  <SvgIcon component={SecondPlayIcon} style={{ marginRight: '13px', marginLeft: '7px' }}></SvgIcon>
                  {open && 'Promoted Content'}
                </ListItem>
              </SidebarLink>

              <SidebarLink
                to="/dashboard/generate-article"
                className={navData => (navData.isActive ? 'active' : 'inactive')}
              >
                <ListItem button>
                  <SvgIcon style={{ marginRight: '13px', marginLeft: '7px' }}>
                    <PerformanceIcon />
                  </SvgIcon>
                  {open && 'Generate Article'}
                </ListItem>
              </SidebarLink>
              {isAdmin && (
                <SidebarLink
                  to="/dashboard/calculator"
                  className={navData => (navData.isActive ? 'active' : 'inactive')}
                >
                  <ListItem button>
                    <SvgIcon style={{ marginRight: '13px', marginLeft: '7px' }}>
                      <EngageIcon />
                    </SvgIcon>
                    {open && 'Calculator'}
                  </ListItem>
                </SidebarLink>
              )}
            </>
          )}
        </List>
        <List style={{ position: 'fixed', bottom: 0, width: open ? drawerWidth : 72 }}>
          {!isAdmin && (
            <ListItem button>
              <ListItemIcon>
                <AccountIcon />
              </ListItemIcon>
              <DrawerListItemText disableTypography={true} primary="Account" />
            </ListItem>
          )}
          {isAdmin && (
            <>
              <ListItemButton onClick={() => navigate('/publishers')}>
                <ListItemIcon>
                  <AccountIcon />
                </ListItemIcon>
                <DrawerListItemText disableTypography={true} primary="Publishers" />
              </ListItemButton>
              <ListItemButton onClick={() => navigate('/users')}>
                <ListItemIcon>
                  <AccountIcon />
                </ListItemIcon>
                <DrawerListItemText disableTypography={true} primary="Users" />
              </ListItemButton>
            </>
          )}
          <ListItem button onClick={logOut}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <DrawerListItemText disableTypography={true} primary="Log Out" />
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: 'white',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        {isDemo && demoPage && (
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {section === 'video-swap' && (
              <ImageSwapDemo
                publishers={publishers}
                selectedPublisher={selectedPublisher}
                setPublisher={setSelectedPublisher}
              />
            )}
            {section === 'calculator' && (
              <Calculator
                selectedPublisher={selectedPublisher}
                publishers={publishers}
                setPublisher={setSelectedPublisher}
              />
            )}
          </Container>
        )}
        {!isDemo && !demoPage && (
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {section === 'keywords' && (
              <Keywords
                publishers={publishers}
                selectedPublisher={selectedPublisher}
                setPublisher={setSelectedPublisher}
              />
            )}
            {section === 'video-swap' && (
              <ImageSwap
                publishers={publishers}
                selectedPublisher={selectedPublisher}
                setPublisher={setSelectedPublisher}
                type="review"
                title="Video Swap"
              />
            )}
            {section === 'video-swap-swapped' && (
              <ImageSwap
                publishers={publishers}
                selectedPublisher={selectedPublisher}
                setPublisher={setSelectedPublisher}
                type="swapped"
                title="Video Swap"
              />
            )}
            {section === 'video-swap-improve' && (
              <ImageSwap
                publishers={publishers}
                selectedPublisher={selectedPublisher}
                setPublisher={setSelectedPublisher}
                type="improve"
                title="Video Swap"
              />
            )}
            {section === 'promoted-content' && (
              <PromotedContent
                publishers={publishers}
                selectedPublisher={selectedPublisher}
                setPublisher={setSelectedPublisher}
              />
            )}
            {section === 'smart-library' && (
              <SmartLibrary
                publishers={publishers}
                selectedPublisher={selectedPublisher}
                setPublisher={setSelectedPublisher}
              />
            )}
            {section === 'publishers' && (
              <Publishers
                publishers={publishers}
                selectedPublisher={selectedPublisher}
                setPublisher={setSelectedPublisher}
              />
            )}
            {/publishers-(add|edit)/.test(section) && <PublishersAdd />}
            {section === 'publishers-articles' && <PublishersDemoArticles />}
            {section === 'user-add' && <UserAdd />}
            {section === 'user-edit' && <UserEdit />}
            {section === 'users' && <Users />}
            {section === 'calculator' && (
              <Calculator
                selectedPublisher={selectedPublisher}
                publishers={publishers}
                setPublisher={setSelectedPublisher}
              />
            )}
            {section === 'generate-article' && <GenerateArticle />}
          </Container>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
